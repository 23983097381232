import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import HomePage from "../components/home";
import Layout from "../components/layout";
import TractPage from "../components/tract";
import FlowmeterPage from "../components/flowmeter";
import SoilSamplePage from "../components/soil";
import ChemigationPage from "../components/chemigation";
import WellPage from "../components/well";
import SettingsPage from "../components/settings";
import CropPage from "../components/crop";
import ProfilePage from "../components/profile";
import NitratesPage from "../components/nitrates";
import NotFound from "../components/404";

const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/tracts" element={<TractPage />} />
      <Route path="/flowmeters" element={<FlowmeterPage />} />
      <Route path="/soil" element={<SoilSamplePage />} />
      <Route path="/chemigations" element={<ChemigationPage />} />
      <Route path="/wells" element={<WellPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/crop" element={<CropPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/nitrates" element={<NitratesPage />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  )
);

export default AppRouter;
