import React from 'react';
import PersonalSection from '../settings/PersonalSection';
import PasswordSection from '../settings/PasswordSection';

const ProfilePage = () => {
    return (
        <div className="w-full min-h-screen">
          <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full p-5 pb-10">
            <h2 className="font-bold text-2xl">Profile</h2>
            <div className="divide-y divide-zinc-200">
              <PersonalSection />
              <PasswordSection />
            </div>
          </div>
        </div>
      );
};

export default ProfilePage;