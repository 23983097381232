import React, { useCallback, useEffect, useMemo, useState } from "react";
import GenericMap from "../map/GenericMap";
import HighLight from "../highlight";
import SoilHealthTrendsChart from "./SoilHealthTrendsChart";
import SoilSampleAccordion from "./SoilSampleAccordion";
import SoilStepper from "./SoilStepper";
import useFetch from "../../hooks/useFetch";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";
import { getBoundBox } from "../../utils/turfHelpers";
import Loading from "../common/Loading";
import Button from "../common/Button";
import { getChartData, getLastestValueForChart } from "../../utils/helpers";
import { pointsWithinPolygon } from "@turf/points-within-polygon";

const SoilSamplePage = () => {
  const [isLoading, setShowLoading] = useState(true);
  const [selectedSoil, setSelectedSoil] = useState();
  const [openModal, setOpenModal] = useState(false);

  const { data, refresh } = useFetch("/soils");
  const { data: tractData } = useFetch("/tracts");

  useEffect(() => {
    if (data) {
      setShowLoading(false);
      if (!selectedSoil && data?.soils?.length) {
        setSelectedSoil(data.soils[0]);
      }
    }
  }, [data, selectedSoil]);

  useEffect(() => {
    if (data?.soils && selectedSoil) {
      const refreshedSelectedSoil = data.soils.find(
        (soil) => soil._id === selectedSoil._id
      );
      if (refreshedSelectedSoil) {
        setSelectedSoil(refreshedSelectedSoil);
      } else {
        setSelectedSoil(null);
      }
    }
  }, [data, selectedSoil]);

  const handleSoilClick = useCallback(
    (type, id) => {
      const newSelectedSoil = (data?.soils || []).find(
        (item) => item._id === id
      );
      if (newSelectedSoil) {
        setSelectedSoil(newSelectedSoil);
      }
    },
    [data?.soils]
  );

  const renderMap = useCallback(
    (isStepper = false) => {
      if (data) {
        let certGeo = null;
        const soilsGeo = convertToFeatureCollection(data.soils, "location_id");

        const allPoints = [];
        (data.soils || [])
          .filter((c) => c?.geometry?.coordinates?.length)
          .forEach((c) => {
            allPoints.push(c?.geometry?.coordinates);
          });

        if (tractData) {
          certGeo = convertToFeatureCollection(
            tractData.TractsDetail,
            "Cert_ID",
            ["Category"]
          );
        }

        let selectedGeo;
        if (selectedSoil) {
          (soilsGeo?.features || []).forEach((item) => {
            if (item.properties.id === selectedSoil._id) {
              item.properties.isSelected = true;
              selectedGeo = item;
            }
          });

          (certGeo?.features || []).forEach(item => {
            if(pointsWithinPolygon(selectedGeo, item)?.features?.length) {
              item.properties.isSelected = true;
            }
          });
        }

        const viewport = calculatePolygonViewport({
          type: "Polygon",
          coordinates: [allPoints],
        }) || {
          latitude: 41.5886072190021,
          longitude: -103.58719705449793,
          zoom: 12.950430835225765,
        };

        let bboxMap;
        try {
          bboxMap = getBoundBox(
            selectedGeo
              ? [selectedGeo]
              : [...soilsGeo.features, ...(certGeo?.features || [])]
          );
        } catch (e) {
          console.warn(e);
        }
        const shouldAddBBox = bboxMap?.some(
          (i) => i !== Infinity && i !== -Infinity
        );

        return (
          <GenericMap
            alwaysShowIcons
            soilsGeo={soilsGeo}
            certGeo={certGeo}
            viewportOverride={viewport}
            bboxMap={shouldAddBBox ? bboxMap : null}
            bboxPadding={100}
            {...(isStepper
              ? { iconSize: 1.5 }
              : {
                  showSoilLocationButton: true,
                  refreshSoilData: refresh,
                  onIconClick: handleSoilClick,
                })}
          />
        );
      }
      return null;
    },
    [data, tractData, selectedSoil, refresh, handleSoilClick]
  );

  const highlight = useMemo(() => {
    if (!data) {
      return null;
    }
    return [
      {
        title: "Soil Residual N",
        value: `${getLastestValueForChart(data, "soil_residual_n")} lbs / ac`,
        percentage: ((data.soil_residual_n_change || 0) * 100).toFixed(1),
        chartProps: {
          chartData: getChartData(data, "soil_residual_n"),
        },
      },
      {
        title: "Soil Residual P",
        value: `${getLastestValueForChart(data, "soil_residual_p")} lbs / ac`,
        percentage: ((data.soil_residual_p_change || 0) * 100).toFixed(1),
        chartProps: {
          chartData: getChartData(data, "soil_residual_p"),
          lineColor: "#9DC34F",
        },
      },
      {
        title: "Soil Residual K",
        value: `${getLastestValueForChart(data, "soil_residual_k")} lbs / ac`,
        percentage: ((data.soil_residual_k_change || 0) * 100).toFixed(1),
        chartProps: {
          chartData: getChartData(data, "soil_residual_k"),
          lineColor: "#396485",
        },
      },
    ];
  }, [data]);

  const tractAcres = useMemo(() => {
    if (selectedSoil?.tract_association_id) {
      const assocTract = (tractData?.TractsDetail || []).find(
        (item) => item._id === selectedSoil.tract_association_id
      );
      if (assocTract) {
        return assocTract.acres;
      }
    }
    return 0;
  }, [selectedSoil, tractData]);

  if (isLoading) {
    return <Loading />;
  }

  if (!tractData?.TractsDetail?.length) {
    return (
      <div className="w-full min-h-screen dark:text-white text-xl font-bold">
        No Tracts found to Add Soil Samples to…. Please contact your district
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">
        Soil Samples Overview
      </h2>
      <div className="my-6 flex gap-x-4">
        {highlight &&
          highlight.map((item, index) => (
            <HighLight key={index} data={item} className="w-full" />
          ))}
      </div>
      <div className="w-full flex-col lg:flex-row flex items-center gap-x-5">
        <div className="w-full lg:w-3/5 h-[472px]">{renderMap()}</div>
        <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full lg:flex-1 p-3 mt-4 lg:mt-0">
          <div className="flex flex-row-reverse items-center justify-between">
            <Button disabled={!selectedSoil} onClick={() => setOpenModal(true)}>
              Add
            </Button>
            <div className="font-bold text-xl">
              {selectedSoil
                ? selectedSoil.location_id
                : "Please select a soil to view the details."}
            </div>
          </div>
          <div className="mt-5 px-5 h-[400px] overflow-y-auto">
            {selectedSoil && (
              <SoilSampleAccordion selectedSoil={selectedSoil} />
            )}
          </div>
        </div>
      </div>
      {selectedSoil?.soil_samples?.length ? (
        <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 p-3">
          <div className="font-bold text-2xl">Soil Trends</div>
          <SoilHealthTrendsChart data={selectedSoil} />
        </div>
      ) : null}
      {selectedSoil && (
        <SoilStepper
          open={openModal}
          setIsOpen={setOpenModal}
          soilId={selectedSoil?._id}
          refreshData={refresh}
          renderMap={renderMap}
          tractAcres={tractAcres}
        />
      )}
    </div>
  );
};

export default SoilSamplePage;
