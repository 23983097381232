import { useMemo } from "react";
import shouldHideSoils from "../../config/shouldHideSoils";

function getIconType(key, data) {
  switch (key) {
    case "wells":
    case "irrWells":
    case "monWells":
    case "miWells":
      if (data?.isSelected) return "wellHighlight";
      switch (data?.Well_Type) {
        case "Irrigation":
          return `irrWell${data?.isSelected ? 'Highlight' : ''}`;
        case "Monitoring (Quality)":
          return `monWell${data?.isSelected ? 'Highlight' : ''}`;
        case "Municipal":
        case "Commercial/Industrial":
          return `miWell${data?.isSelected ? 'Highlight' : ''}`;
        default:
          return `well${data?.isSelected ? 'Highlight' : ''}`;
      }
    case 'flowmeters':
      if (data?.isSelected) return 'meterHighlight';
      if (data?.hasCurrentRead) return 'fmGreen';
      return 'fmRed';
    case 'chemigations':
      if (data?.isSelected) return 'chemHighlight';
      return 'chemigation';
    case 'soils':
      if (data?.isSelected) return 'soilHighlight';
      return 'soil';
    default:
      throw new Error("Invalid key");
  }
}

// combines wells, FMs and chemigations (for clustering)
export default function useCombinedPointGeom({
  wellsGeo,
  flowmetersGeo,
  chemigationsGeo,
  soilsGeo,
  shownLayers,
}) {
  const pointGeom = useMemo(() => {
    const features = [];
    [
      { key: "irrWells", type: "Well", data: wellsGeo },
      { key: "monWells", type: "Well", data: wellsGeo },
      { key: "miWells", type: "Well", data: wellsGeo },
      { key: "wells", type: "Well", data: wellsGeo },
      { key: "flowmeters", type: "Flowmeter", data: flowmetersGeo },
      { key: "chemigations", type: "Chemigation", data: chemigationsGeo },
      ...(shouldHideSoils
        ? []
        : [{ key: "soils", type: "Soils", data: soilsGeo }]),
    ].forEach(({ key, type, data }) => {
      if (shownLayers[key] && data) {
        if (data.type === "FeatureCollection") {
          features.push(
            ...data.features
              .map((feature) => {
                feature.properties.type = type;
                feature.properties.iconType = getIconType(
                  key,
                  feature.properties
                );

                return feature;
              })
              .filter((feature) => {
                if (type !== "Well") return true;
                // filter wells into their appropriate layers
                // this is usually done by iconType
                // the exception is that if the well's "primary layer"
                // is hidden but the monWells layer is shown and the well
                // also has Mon_Active=true, then we want to move it into
                // the monWells feature array
                const wellType = `${feature.properties.iconType.replace('Highlight', '')}s`;
                if (
                  !shownLayers[wellType] &&
                  shownLayers.monWells &&
                  feature.properties.Mon_Active
                ) {
                  return key === "monWells";
                }
                return key === wellType;
              })
          );
        } else {
          const iconType = getIconType(key, data.properties);
          
          if (
            key === "flowmeters" ||
            key === "chemigations" ||
            key === "soils" ||
            key.slice(0, -1) === iconType
          ) {
            features.push({
              ...data,
              properties: {
                ...data.properties,
                type,
                iconType,
              },
            });
          }
        }
      }
    });

    return {
      type: "FeatureCollection",
      features,
    };
  }, [wellsGeo, flowmetersGeo, chemigationsGeo, soilsGeo, shownLayers]);

  return pointGeom;
}
