import React, { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import SoilSampleInfo from "./SoilSampleInfo";

const SoilSampleAccordion = ({ selectedSoil }) => {
  const [itemOpen, setItemOpen] = useState(null);

  const samples = selectedSoil.soil_samples || [];
  const selectedSample = itemOpen !== null ? samples[itemOpen] : null;

  const handleHeaderClick = (index) => {
    if (index === itemOpen) {
      setItemOpen(null);
    } else {
      setItemOpen(index);
    }
  };

  if (!samples?.length) {
    return <div>No Soil Samples available</div>;
  }

  return (
    <>
      {samples.map((item, index) => (
        <div key={item._id}>
          <div
            className="flex items-center justify-between py-3 cursor-pointer"
            onClick={() => handleHeaderClick(index)}
          >
            <div>Sample {moment(item.sample_date).format("MM-DD-YYYY")}</div>
            <div>{`N: ${item.n_lbs} P: ${item.p_lbs} K: ${item.k_lbs}`}</div>
            <ChevronRightIcon width={20} height={20} />
          </div>
        </div>
      ))}
      <SoilSampleInfo
        open={itemOpen !== null}
        soilId={selectedSoil?._id}
        sampleId={selectedSample?._id}
        setIsOpen={() => {
          setItemOpen(null);
        }}
      />
    </>
  );
};

export default SoilSampleAccordion;
