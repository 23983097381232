import React, { useState } from "react";
import { PhotoIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDropzone } from "react-dropzone";

const ImagePicker = ({onSave, onRemove}) => {
  const [tempPicture, setTempPicture] = useState(null);

  function onDrop([file]) {
    setTempPicture(file);
    onSave(file);
  }

  function handleRemove(e) {
    e.stopPropagation();
    setTempPicture(null);
    onRemove();
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className="relative bg-white dark:bg-neutral-700 w-40 h-40 min-w-[80px] rounded-md ring-1 ring-inset ring-gray-300 flex flex-col items-center justify-center p-1"
    >
      <input {...getInputProps()} />
      {tempPicture ? (
        <>
          <img src={URL.createObjectURL(tempPicture)} alt="MeterReadPicture" className="h-full w-full object-contain" />
          <span className="absolute">Replace picture</span>
          <TrashIcon className="w-5 h-5 absolute top-1 right-1 cursor-pointer text-red-600" onClick={handleRemove} />
        </>
      ) : (
        <div className="cursor-pointer flex flex-col items-center">
          <PhotoIcon className="w-10 h-10" />
          <span>Upload picture</span>
        </div>
      )}
    </div>
  );
};

export default ImagePicker;
