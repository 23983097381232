import React, { useEffect, useMemo, useRef, useState } from "react";
import HighLight from "../highlight";
import GenericMap from "../map/GenericMap";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";
import TableData from "../table";
import CropInfo from "./CropInfo";
import useFetch from "../../hooks/useFetch";
import Loading from "../common/Loading";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import { getBoundBox } from "../../utils/turfHelpers";
import { useUserContext } from "../../context/user";
import Button from "../common/Button";
import CropAddNew from "./CropAddNew";

const CropPage = () => {
  const cropRef = useRef(null);
  const [selectedTract, setSelectedTract] = useState();
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [openTray, setOpenTray] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setShowLoading] = useState(true);

  const [userState] = useUserContext();

  const { data, refresh } = useFetch("/tracts/crops");

  useEffect(() => {
    if (data) {
      setShowLoading(false);
      if (data.Tracts?.length && !selectedTract) {
        setSelectedTract(data.Tracts[0]);
      }
    }
  }, [data, selectedTract]);

  useEffect(() => {
    if (data?.Tracts && selectedTract) {
      const refreshedSelectedTract = data.Tracts.find(
        (tract) => tract._id === selectedTract._id
      );
      if (refreshedSelectedTract) {
        setSelectedTract(refreshedSelectedTract);
      } else {
        setSelectedTract(null);
        setSelectedCrop(null);
      }
    }
  }, [data, selectedTract]);

  const highlight = useMemo(() => {
    if (data) {
      return [
        {
          title: "Total Acres",
          value: data.total_acres,
        },
        {
          title: "Total Sprinkler Acres",
          value: data.total_sprinkler_acres,
        },
        {
          title: "Average Corn Yield",
          value: `${data.avg_corn_yield} bu/ac`,
        },
      ];
    }
    return null;
  }, [data]);

  const map = useMemo(() => {
    if (data) {
      const allPoints = [];
      (data.Tracts || [])
        .filter((c) => c?.geometry?.coordinates?.length)
        .forEach((c) => {
          if (c.geometry.type === "MultiPolygon") {
            c.geometry.coordinates
              .reduce((acc, [arr]) => [...acc, ...arr], [])
              .forEach((p) => allPoints.push(p));
          } else
            (c.geometry.coordinates[0] || []).forEach((p) => allPoints.push(p));
        });
      const viewport = calculatePolygonViewport({
        type: "Polygon",
        coordinates: [allPoints],
      }) || {
        latitude: 41.5886072190021,
        longitude: -103.58719705449793,
        zoom: 12.950430835225765,
      };
      let selectedGeo;
      const certGeo = convertToFeatureCollection(data.Tracts || [], "Cert_ID", [
        "Category",
      ]);
      (certGeo?.features || []).forEach((item) => {
        const isSelected = item.properties.id === selectedTract?._id;
        item.properties.isSelected = isSelected;
        if (isSelected) {
          selectedGeo = item;
        }
      });
      let bboxMap;
      try {
        bboxMap = getBoundBox([
          ...(selectedGeo ? [selectedGeo] : certGeo.features),
        ]);
      } catch (e) {
        console.warn(e);
      }
      const shouldAddBBox = bboxMap?.some(
        (i) => i !== Infinity && i !== -Infinity
      );

      return (
        <GenericMap
          certGeo={certGeo}
          viewportOverride={viewport}
          expandable
          editingEndpoint="certs"
          bboxMap={shouldAddBBox ? bboxMap : undefined}
          onIconClick={(type, id) => {
            const tractToSelect = data.Tracts.find((item) => item._id === id);
            if (tractToSelect) {
              setSelectedTract(tractToSelect);
            }
          }}
        />
      );
    }
    return null;
  }, [data, selectedTract]);

  const handleSelectTract = (item) => {
    setSelectedTract(item);
    setTimeout(() => {
      if (cropRef.current) {
        cropRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">
        Crops and Management
      </h2>
      <div className="my-6 flex gap-x-4">
        {highlight.map((item, index) => (
          <HighLight key={index} data={item} className="w-full" />
        ))}
      </div>
      <div className="flex flex-col lg:flex-row my-10 gap-x-5 items-start">
        <div className="w-full lg:w-3/5 h-[472px]">{map}</div>
        {selectedTract && (
          <div
            ref={cropRef}
            className="w-full lg:flex-1 mt-4 lg:mt-0 bg-white dark:bg-gray-900 dark:text-white p-3 lg:max-w-2xl mx-auto rounded-lg"
          >
            <div className="flex items-center justify-between mb-5">
              <h5 className="font-bold text-xl">
                Tract {selectedTract.Cert_ID} Crops
              </h5>
              {userState?.can_edit_crop && (
                <Button onClick={() => setOpenModal(true)}>Add</Button>
              )}
            </div>
            {selectedTract.Crops && (
              <TableData
                data={selectedTract.Crops.sort((a, b) =>
                  a.Year > b.Year ? -1 : 1
                )}
                page={1}
                canLoadMore={false}
                headerItems={
                  <>
                    <div className="flex-1 px-5">YEAR</div>
                    <div className="flex-1 px-5">CROP</div>
                    <div className="flex-1 px-5">ACRES</div>
                  </>
                }
                renderRow={(item, index) => (
                  <div
                    className="flex w-full py-4 cursor-pointer"
                    onClick={() => {
                      setSelectedCrop(item);
                      setOpenTray(true);
                    }}
                  >
                    <div className="flex-1 px-5">{item.Year}</div>
                    <div className="flex-1 px-5">{item.Crop_Planted}</div>
                    <div className="flex-1 px-5">{item.Other_Irr_Acres}</div>
                  </div>
                )}
              />
            )}
          </div>
        )}
      </div>
      {data && (
        <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 p-3">
          <TableData
            data={data.Tracts || []}
            page={1}
            headerItems={
              <>
                <div className="flex-1 text-center">TRACT ID</div>
                <div className="flex-1 text-left">TYPE</div>
                <div className="flex-1 text-left">USE TYPE</div>
                <div className="flex-1 text-left">ALIAS</div>
                <div className="flex-1 text-left">LEGAL LOCATION</div>
              </>
            }
            renderRow={(item, index) => (
              <div
                key={index}
                onClick={() => handleSelectTract(item)}
                className={`flex w-full py-4 cursor-pointer ${
                  item.Cert_ID === selectedTract?.Cert_ID ? "bg-gray-1" : ""
                }`}
              >
                <div className="flex-1 text-center">{item.Cert_ID}</div>
                <div className="flex-1">{item.Category}</div>
                <div className="flex-1">{item.cert_type}</div>
                <div className="flex-1">{item.Alias}</div>
                <div className="flex-1">{item.Legal}</div>
              </div>
            )}
          />
        </div>
      )}
      <CropAddNew
        tractId={selectedTract?._id}
        tractAcres={selectedTract?.acres}
        open={openModal}
        setIsOpen={setOpenModal}
        refreshData={refresh}
      />
      <CropInfo
        tractId={selectedTract?._id}
        data={selectedCrop}
        open={openTray}
        setIsOpen={() => {
          setOpenTray(false);
          setSelectedCrop(null);
        }}
        isAdding={selectedCrop === null}
        refreshData={refresh}
      />
    </div>
  );
};

export default CropPage;
