export default function calculatePolygonViewport(geometry) {
    if (!geometry) return null;
  
    // get a flat array of coordinates
    const coords = geometry.type === 'MultiPolygon'
      ? geometry.coordinates.reduce(
        (acc, [arr]) => [...acc, ...arr],
        [],
      )
      : geometry.coordinates[0];
  
    // find the average cert coords
    const [avgLong, avgLat] = coords
      .reduce(
        ([accLong, accLat], [long, lat]) => [accLong + long, accLat + lat],
        [0, 0],
      )
      .map((num) => num / coords.length);
  
    // figure out the default zoom level using the latitude variance
    const latExtremes = coords.reduce(
      ([minLat, maxLat], [long, lat]) => [Math.min(lat, minLat), Math.max(lat, maxLat)],
      [180, 0],
    );
  
    const latVariance = latExtremes[1] - latExtremes[0];
  
    const defaultZoom = 14 - ((latVariance / 0.0035) ** 0.4);
  
    // make sure none of the 3 are NaN to avoid errors
    if (!isNaN(avgLat) && !isNaN(avgLong) && !isNaN(defaultZoom)) {
      return {
        latitude: avgLat,
        longitude: avgLong,
        zoom: defaultZoom,
      };
    }
  
    return null;
  }
  