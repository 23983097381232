import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../utils/fetch";

const initialState = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },
  },
});

export const fetchNavbar = createAsyncThunk(
  "user/fetchNavbar",
  async (_, thunkAPI) => {
    try {
      const response = await request({ url: 'home/navbar' });
      thunkAPI.dispatch(setCurrentUser({
        ...thunkAPI.getState()?.user?.currentUser,
        ...response,
      }));
      return response;
    } catch (error) {}
  }
);

export const { setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
