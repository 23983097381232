export default function fileUpload(fileType, callback, changeCallback) {
  // create the element to trigger the upload
  const input = document.createElement("input");
  input.type = "file";
  input.style = "display: none;";
  if (fileType) input.accept = `.${fileType}`;

  // when the user uploads a file, load it using FileReader
  function handleChange(e) {
    if (changeCallback) changeCallback(e, callback);
    else {
      const [file] = this.files;
      callback(file);
    }
  }
  input.addEventListener("change", handleChange);

  // trigger the upload
  document.body.appendChild(input);
  input.click();
  setTimeout(() => {
    document.body.removeChild(input);
  });
}
