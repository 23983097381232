import axios from 'axios';
import { apiRootURL } from '../config/keys';
import { getAccessToken } from '../utils/auth';

export const request = async ({ method = 'get', url, apiUrl = apiRootURL, headers = {}, data = {}, params, ...rest }) => {
  const accessToken = await getAccessToken();
  // const jwtToken = accessToken?.jwtToken;
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = accessToken;
  }

  try {
    const res = await axios.request({
      baseURL: apiUrl,
      url,
      method: method.toLowerCase(),
      headers,
      data,
      params,
      ...rest,
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getRequest = params => request({ ...params, method: 'get' });
export const postRequest = params => request({ ...params, method: 'post' });
export const putRequest = params => request({ ...params, method: 'put' });
export const deleteRequest = params => request({ ...params, method: 'delete' });

export default request;