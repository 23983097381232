import React from "react";
import AppRouter from "./router";
import { RouterProvider } from "react-router-dom";
import { AppComposite } from "./context";

function App() {
  return (
    <AppComposite>
      <RouterProvider router={AppRouter} />
    </AppComposite>
  );
}

export default App;
