import { fetchAuthSession } from "aws-amplify/auth";

export const getAccessToken = async () => {
  try {
    const session = await fetchAuthSession();
    return session.tokens.accessToken.toString();
  } catch (error) {
    console.log("Error:", error);
    return null;
  }
};
