import React, { useState } from "react";
import TraySlideOut from "../common/TraySlideOut";
import Toggle from "../common/Toggle";
import { FormikProvider, useFormik } from "formik";
import Input from "../common/form/Input";
import Select from "../common/form/Select";
import Button from "../common/Button";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import displayValue from "../../utils/displayValue";

const getDefaultInputs = (data) =>
  Object.entries(data).reduce((result, [key, value]) => {
    if (key === "Nitrogen_Inhibitors" || key === "Nitrogen_Applications") {
      if (value?.length) {
        value.forEach((nitrate, index) => {
          Object.entries(nitrate).forEach(([nitrateKey, nitrateValue]) => {
            result[`${key}-${index + 1}-${nitrateKey}`] = nitrateValue;
          });
        });
        result[key] = value.length;
      }
    } else {
      result[key] = value;
    }
    return result;
  }, {});

const CropInfo = ({
  data,
  open,
  setIsOpen,
  isAdding,
  tractId,
  refreshData,
}) => {
  const [isLoading, setLoading] = useState(false);

  const defaultInputs = getDefaultInputs(data || {});

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        await request({
          method: "post",
          url: `/tracts/${tractId}/AddCrop`,
          data: values,
        });
        refreshData();
        toast.success("Successfully added crop");
        setLoading(null);
        setIsOpen(false);
        resetForm();
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const renderItem = ({
    label,
    key,
    suffix,
    bool,
    textarea,
    number,
    dropdown,
    options,
    date,
  }) => {
    const labelToShow = label || key.split("_").join(" ");
    let valueToShow = defaultInputs?.[key];
    if (key.includes(".")) {
      const [key1, key2] = key.split(".");
      valueToShow = defaultInputs?.[key1] && defaultInputs?.[key1][key2];
    }

    if (bool) {
      return (
        <div key={key} className="sm:col-span-1">
          <Toggle
            key={key}
            value={Boolean(valueToShow) || formik.values[key]}
            label={labelToShow}
            disabled={!isAdding}
            onChange={(value) => formik.setFieldValue(key, value)}
          />
        </div>
      );
    }
    if (isAdding) {
      if (dropdown) {
        return (
          <Select
            name={key}
            label={labelToShow}
            extraOptions={options}
            onChange={(option) => {
              formik.setFieldValue(key, option);
            }}
          />
        );
      }
      return (
        <Input
          name={key}
          label={labelToShow}
          textarea={textarea}
          suffix={suffix}
          type={number ? "number" : "text"}
          onChange={(e) => {
            formik.setFieldValue(
              key,
              number ? parseFloat(e.target.value) : e.target.value
            );
            formik.setFieldTouched(key);
          }}
        />
      );
    }
    return (
      <div key={key} className="sm:col-span-1">
        <label className="font-semibold text-sm">{labelToShow}: </label>
        <div className="mt-1 text-sm">
          {displayValue(valueToShow, { date })} {suffix || ""}
        </div>
      </div>
    );
  };

  const gridClass = "w-100 grid grid-cols-1 md:grid-cols-2 gap-4 mt-3";

  if (!data && !isAdding) {
    return null;
  }

  const fields = (
    <div className="p-6">
      <div className={`${gridClass} mb-4 mt-6 first:mt-0 last:mb-0`}>
        {[
          { key: "Year", number: true },
          {
            key: "Crop_Planted",
            dropdown: true,
            options: [
              "Alfalfa",
              "Corn",
              "Dry Beans",
              "Fallow",
              "Field Beans",
              "Grain Sorghum",
              "Grass",
              "Millet",
              "Oats",
              "Other",
              "Potatoes",
              "Small Grains",
              "Sorghum / Sudan Grass",
              "Sorghum Sudan Grass",
              "Soybeans",
              "Sugar Beets",
              "Sunflowers",
              "Wheat",
            ],
          },
          { key: "Crop_Yield", number: true },
          {
            key: "Crop_Yield_Units",
            dropdown: true,
            options: ["bushels / ac", "pounds / ac", "tons / ac"],
          },
          { key: "Sprinkler_Acres", number: true },
          { key: "Flood_Acres", number: true },
          { key: "Other_Irr_Acres", number: true },
          { key: "Irrigation_Water_Applied", suffix: "inches", number: true },
          { key: "Water_Nitrate", suffix: "ppm NO-3", number: true },
          {
            key: "Tillage_Practice",
            dropdown: true,
            options: ["Conventional", "Minimum", "No-till"],
          },
        ].map(renderItem)}
      </div>
      <div className={`w-100 grid grid-cols-1 gap-4 mt-3 first:mt-0 last:mb-0`}>
        {[
          {
            key: "Note",
            label: "Note",
            textarea: true,
          },
        ].map(renderItem)}
      </div>
      <hr class="mt-6" />
      {defaultInputs?.Nitrogen_Applications ? (
        <>
          <div className="mb-4 mt-3">
            <h5 className="text-base font-semibold inline my-2 mx-auto">
              Nitrogen Applied
            </h5>
            {Array(defaultInputs.Nitrogen_Applications || 0)
              .fill("")
              .map((item, index) => (
                <div
                  key={index}
                  className={`${gridClass} border-b pb-4 first:mt-0 last:mb-0 last:border-b-0`}
                >
                  <div className="font-bold col-span-2">
                    Applications {index + 1}
                  </div>
                  {[
                    {
                      key: `Nitrogen_Applications-${index + 1}-date_applied`,
                      label: "Date Applied",
                      date: true,
                    },
                    {
                      key: `Nitrogen_Applications-${index + 1}-emergent_type`,
                      label: "Emergence Type",
                      dropdown: true,
                      options: ["Pre-emergence", "Post-emergence"],
                    },
                    {
                      key: `Nitrogen_Applications-${index + 1}-type`,
                      label: "Type",
                    },
                    {
                      key: `Nitrogen_Applications-${index + 1}-amount`,
                      label: "Amount",
                      number: true,
                    },
                    {
                      key: `Nitrogen_Applications-${index + 1}-units`,
                      label: "Units",
                      dropdown: true,
                      options: ["Lbs/Ac", "Gallons/Ac", "Oz/Ac", "Liters/Ac"],
                    },
                  ].map(renderItem)}
                </div>
              ))}
          </div>
          <hr class="mt-6" />
        </>
      ) : null}
      {defaultInputs?.Nitrogen_Inhibitors ? (
        <div className="mb-4 mt-3">
          <h5 className="text-base font-semibold inline my-2 mx-auto">
            Nirogen Inhibitor
          </h5>
          {Array(defaultInputs.Nitrogen_Inhibitors || 0)
            .fill("")
            .map((item, index) => (
              <div
                key={index}
                className={`${gridClass} border-b pb-4 first:mt-0 last:mb-0 last:border-b-0`}
              >
                <div className="font-bold col-span-2">
                  Inhibitors {index + 1}
                </div>
                {[
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-date_applied`,
                    label: "Date Applied",
                    date: true,
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-type`,
                    label: "Type",
                    dropdown: true,
                    options: ["Biologic", "Chemical"],
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-name`,
                    label: "Name",
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-amount`,
                    label: "Amount",
                    number: true,
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-units`,
                    label: "Units",
                    dropdown: true,
                    options: ["Lbs/Ac", "Gallons/Ac", "Oz/Ac", "Liters/Ac"],
                  },
                  {
                    key: `Nitrogen_Inhibitors-${index + 1}-acres_treated`,
                    label: "Acres Treated",
                    number: true,
                  },
                ].map(renderItem)}
              </div>
            ))}
        </div>
      ) : null}
      {isAdding && (
        <div className="flex justify-end">
          <Button type="submit" disabled={isLoading} loading={isLoading}>
            Save
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <TraySlideOut
      title="Crop Information"
      onClose={() => {
        setTimeout(() => setIsOpen(false), 100);
      }}
      open={open}
    >
      {isAdding ? (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>{fields}</form>
        </FormikProvider>
      ) : (
        fields
      )}
    </TraySlideOut>
  );
};

export default CropInfo;
