import React, { useEffect } from "react";
import TraySlideOut from "../common/TraySlideOut";
import displayValue from "../../utils/displayValue";
import { useLazyFetch } from "../../hooks/useFetch";
import Loading from "../common/Loading";
import Toggle from "../common/Toggle";
import ActivityIcon from "../common/ActivityIcon";

const SoilSampleInfo = ({ soilId, sampleId, open, setIsOpen }) => {
  const [fetchSampleDetail, { data, loading }] = useLazyFetch("");

  useEffect(() => {
    if (soilId && sampleId) {
      fetchSampleDetail(`/soils/${soilId}/samples/${sampleId}/`);
    }
  }, [sampleId, soilId]);

  const renderItem = ({ label, key, suffix, percent, date, bool }) => {
    let valueToShow = data?.[key];
    if (valueToShow) {
      if (percent) {
        valueToShow = `${(valueToShow * 100).toFixed(1)}%`;
      } else if (date) {
        valueToShow = displayValue(valueToShow, { date: true });
      }
    }
    if (bool) {
      return (
        <div key={key} className="sm:col-span-1 flex items-end">
          <Toggle
            key={key}
            value={Boolean(valueToShow)}
            label={label}
            disabled
          />
        </div>
      );
    }
    return (
      <div key={key} className="sm:col-span-1">
        <label className="font-semibold text-sm">{label}: </label>
        <div className="mt-1 text-sm">
          {valueToShow || "N/A"} {suffix || ""}
        </div>
      </div>
    );
  };

  return (
    <TraySlideOut
      title="Soil Sample Information"
      onClose={() => {
        setTimeout(() => setIsOpen(false), 100);
      }}
      open={open}
    >
      <div className="p-6">
        {loading ? (
          <Loading />
        ) : data ? (
          <div className="w-100 grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-6 first:mt-0 last:mb-0">
            {[
              { key: "Sample_ID", label: "Sample ID" },
              { key: "Sample_Date", label: "Sample Date", date: true },
              { key: "Lab_Date", label: "Lab Date", date: true },
              { key: "Lab_Name", label: "Lab Name" },
              { key: "Acres_Represented", label: "Acres Represented" },
              { key: "Accepted_Data", label: "Accepted Data", bool: true },
            ].map(renderItem)}
            <ActivityIcon
              pill
              active={!!data?.user_supplied}
              onText="User Supplied"
              offText="NRD Entered"
            />
            <div className="mt-3 border-t pt-3 col-span-2">
              <h5 className="font-bold">Soil Layer Information</h5>
              <div className="flex items-center justify-between mt-3">
                <Toggle
                  label="Composite Sample"
                  value={data.composite_sample}
                  disabled
                />
              </div>
              {(data?.Soil_Layers || []).map((item, index) => (
                <div key={`layer_${index}`} className="py-3 border-b">
                  <div className="font-semibold">Layer {index + 1}</div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                    <div className="sm:col-span-1">
                      <label className="font-semibold text-sm">Top Depth</label>
                      <div className="mt-1 text-sm">
                        {displayValue(item.TopDepth)}
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <label className="font-semibold text-sm">
                        Bottom Depth
                      </label>
                      <div className="mt-1 text-sm">
                        {displayValue(item.BottomDepth)}
                      </div>
                    </div>
                    {(item.Properties || []).map((property) =>
                      property.ResultName && property.ResultName !== "" ? (
                        <div
                          key={`${index}_${property.ResultName}`}
                          className="sm:col-span-1"
                        >
                          <label className="font-semibold text-sm">
                            {property.ResultName}
                          </label>
                          <div className="mt-1 text-sm">
                            {displayValue(property.ResultValue)}
                            {property.ResultUnits
                              ? ` ${property.ResultUnits}`
                              : ""}
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </TraySlideOut>
  );
};

export default SoilSampleInfo;
