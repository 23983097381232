import { useEffect, useState } from "react";
import queryString from "query-string";

import useCreateMap from "./useCreateMap";
import useCombinedPointGeom from "./useCombinedPointGeom";
import useMapLayersSetup from "./useMapLayersSetup";
import useMapDataUpdate from "./useMapDataUpdate";
import useMapUserActions from "./useMapUserActions";
import useViewportOverride from "./useViewportOverride";
import { useAxiosDebounce } from "../useDebounce";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";

function tractBgColor(type) {
  if (type === "Certified Acres") {
    return "#dbabf5";
  }
  return "#00ff00";
}

export default function useMap({
  viewportOverride,
  viewportCallback,
  mapContainer,
  certGeo,
  irrigatedGeo,
  retirementGeo,
  wellsGeo,
  flowmetersGeo,
  chemigationsGeo,
  soilsGeo,
  shownLayers,
  layersOverride,
  alwaysShowIcons,
  wur,
  showLevelsInPopup,
  labelWellsWithRegNo,
  shouldAddNRDBoundaries,
  onIconClick,
  iconSize,
  displayTownships,
}) {
  const [viewport, setViewport] = useState(null);
  const { params, shouldInvalid } = useViewportOverride({
    viewport,
  });
  const { neLong, neLat, swLong, swLat } = params;
  const { data: townshipsGeo, setData: setTownshipsGeo } = useAxiosDebounce(
    {
      path: "/layers/townships",
      params,
      key: "Id",
      cb: (arr) =>
        setTownshipsGeo(
          convertToFeatureCollection(arr, "Label", ["Id"], "Townships")
        ),
    },
    [neLong, neLat, swLong, swLat],
    !displayTownships || shouldInvalid(9, 12)
  );
  const { data: sectionsGeo, setData: setSectionsGeo } = useAxiosDebounce(
    {
      path: "/layers/sections",
      params,
      key: "Id",
      cb: (arr) =>
        setSectionsGeo(
          convertToFeatureCollection(arr, "Label", ["Id"], "Sections")
        ),
    },
    [neLong, neLat, swLong, swLat],
    shouldInvalid(12)
  );

  const { mapRef, draw, mapLoaded } = useCreateMap({
    viewportOverride,
    mapContainer,
  });

  let certGeom = null;
  if (certGeo) {
    let filteredCertFeatures = [];
    if (certGeo.features && certGeo.features.length) {
      if (shownLayers) {
        // eslint-disable-next-line max-len
        filteredCertFeatures = certGeo.features.filter(
          (data) => shownLayers[data.properties.Category] === true
        );
      } else {
        filteredCertFeatures = [...certGeo.features];
      }
    } else if (certGeo.properties) {
      if (shownLayers) {
        // eslint-disable-next-line max-len
        filteredCertFeatures =
          shownLayers[certGeo.properties.Category] === true
            ? [{ ...certGeo }]
            : [];
      } else {
        filteredCertFeatures = [{ ...certGeo }];
      }
    }
    certGeom = { ...certGeo, features: [...filteredCertFeatures] };
    certGeom.features.forEach((cf, i) => {
      certGeom.features[i].properties.callback = cf.properties.callback;
      if (cf.properties.isSelected) {
        certGeom.features[i].properties.bgcolor = "#fffb96";
        certGeom.features[i].properties.borderColor = "#ffd156";
      } else {
        certGeom.features[i].properties.bgcolor = tractBgColor(
          cf.properties.Category
        );
        certGeom.features[i].properties.borderColor = "#af53e0";
      }
    });
  }

  const pointGeom = useCombinedPointGeom({
    wellsGeo,
    flowmetersGeo,
    chemigationsGeo,
    soilsGeo,
    shownLayers,
  });

  useMapLayersSetup({
    mapRef,
    certGeom,
    irrigatedGeo,
    retirementGeo,
    pointGeom,
    layersOverride,
    alwaysShowIcons,
    wur,
    labelWellsWithRegNo,
    shouldAddNRDBoundaries,
    iconSize,
    townshipsGeo,
    sectionsGeo,
  });

  useMapDataUpdate({
    mapRef,
    certGeom,
    irrigatedGeo,
    pointGeom,
    retirementGeo,
    townshipsGeo,
    sectionsGeo,
  });

  useMapUserActions({
    mapRef,
    viewportCallback: setViewport,
    certGeom,
    retirementGeo,
    showLevelsInPopup,
    onIconClick,
  });

  useEffect(() => {
    function handleViewportUpdate() {
      const {
        transform: { _center: center, _zoom: zoom },
      } = mapRef.current;
      const { lng: longitude, lat: latitude } = center;
      const bounds = mapRef.current.getBounds();

      if (!bounds) return;

      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      if (window.location.pathname === "/map") {
        const { ls } = queryString.parse(window.location.search);
        window.history.pushState(
          {},
          "",
          `?${queryString.stringify({
            x: longitude,
            y: latitude,
            z: zoom,
            ls,
          })}`
        );
      }
      if (viewportCallback) {
        viewportCallback({
          longitude,
          latitude,
          zoom,
          neLong: ne.lng,
          neLat: ne.lat,
          swLong: sw.lng,
          swLat: sw.lat,
        });
      }
    }

    handleViewportUpdate();
    // eslint-disable-next-line
  }, [mapRef, mapLoaded]);

  return { mapRef, draw, mapLoaded };
}
