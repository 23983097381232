import React from "react";

const NotFound = () => {
  return (
    <div className="w-full min-h-screen px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-primary-light">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight dark:text-white sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 dark:text-white">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-10">
          <a
            href="/"
            className="text-sm font-semibold leading-7 text-primary-light"
          >
            <span aria-hidden="true">&larr;</span> Back to home
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
