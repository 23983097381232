import React, { useMemo } from "react";
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line,
  ResponsiveContainer,
} from "recharts";

const UsageChart = ({ usageData, certId }) => {
  function CustomTooltip({ payload, label, active }) {
    if (payload && label && active) {
      return (
        <div className="custom-tooltip">
          {payload[0].payload.perAcre != null ? (
            <>
              <p className="label text-black">
                <u>Use Year: {label}</u>
              </p>
              <p className="text-dark-blue">{payload[0].value} Ac-In</p>
            </>
          ) : (
            <p className="text-dark-blue">{`${label} : ${payload[0].value}`}</p>
          )}

          {payload[0].payload.perAcre != null ? (
            <p className="text-primary">
              {" "}
              {payload[0].payload.perAcre} Ac-In/Ac
            </p>
          ) : null}
        </div>
      );
    }
    return null;
  }

  const chartData = useMemo(() => {
    const chartData = [];
    Object.keys(usageData.perAcre).forEach((year) => {
      const date = new Date(`10/01/${year}`);
      date.setDate(1);
      date.setHours(0, 0, 0, 0);
      chartData.push({
        Date: date,
        perAcre: usageData.perAcre?.[year],
        Use_ai: usageData.total?.[year],
        DateStr: String(date.getFullYear()),
      });
    });
    return chartData;
  }, [usageData.perAcre, usageData.total]);

  return (
    <div
      id="CertUsageChart"
      className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 px-3 py-5"
    >
      <div className="font-bold text-2xl mb-3">{`Tract ${certId} Usage in Acre Inches`}</div>
      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={200}>
          <ComposedChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="DateStr" />
            <YAxis yAxisId="Use_ai" />
            <YAxis
              yAxisId="perAcre"
              orientation="right"
              label={{
                value: "Ac-In / ac",
                angle: -90,
                position: "insideRight",
              }}
            />
            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={{
                width: 140,
                backgroundColor: "#f4f4f4",
                padding: 10,
              }}
            />
            <Bar dataKey="Use_ai" yAxisId="Use_ai" fill="#65A7DD" />
            <Line
              type="monotone"
              dataKey="perAcre"
              yAxisId="perAcre"
              stroke="#396485"
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <div>No Usage Recorded</div>
      )}
    </div>
  );
};

export default UsageChart;
