import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericMap from "../map/GenericMap";
import {
  EnvelopeIcon,
  TrashIcon,
  EnvelopeOpenIcon,
} from "@heroicons/react/24/solid";
import HighLight from "../highlight";
import { NOTIFICATIONS } from "../../data/home";
import TableData from "../table";
import useFetch from "../../hooks/useFetch";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import { getBoundBox } from "../../utils/turfHelpers";
import Loading from "../common/Loading";
import moment from "moment";
import MessageDetailModal from "./MessageDetailModal";
import request from "../../utils/fetch";
import ConfirmModal from "../common/ConfirmModal";
import toast from "react-hot-toast";
import { useUserContext } from "../../context/user";

const LIMIT = 10;

const HomePage = () => {
  const [page, setPage] = useState(1);
  const [activeMessage, setActiveMessage] = useState();
  const [messageToDelete, setMessageToDelete] = useState();
  const [canLoadMore, setCanLoadMore] = useState(
    LIMIT * page < NOTIFICATIONS.length
  );
  const [isLoading, setShowLoading] = useState(true);

  const { data } = useFetch("/home");
  const { data: messages, refresh: refreshMessages } = useFetch("/messages");

  const [, { fetchNavbar }] = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setShowLoading(false);
    }
  }, [data]);

  const handleScrollBottom = () => {
    if (LIMIT * page < NOTIFICATIONS.length) {
      setTimeout(() => {
        setPage(page + 1);
      }, 3000);
    } else {
      setCanLoadMore(false);
    }
  };

  const handleMessageClick = useCallback(
    async (item) => {
      setActiveMessage(item);
      try {
        await request({
          url: `/messages/${item._id}/SetRead`,
          method: "put",
        });
        fetchNavbar();
        refreshMessages();
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      }
    },
    [refreshMessages, fetchNavbar]
  );

  const handleDeleteMessage = useCallback(async () => {
    try {
      await request({
        url: `/messages/${messageToDelete._id}`,
        method: "delete",
      });
      refreshMessages();
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      setMessageToDelete(null);
    }
  }, [refreshMessages, messageToDelete]);

  const handleIconClick = useCallback(
    (type, id) => {
      switch (type) {
        case "Tract":
          navigate(`/tracts?id=${id}`);
          break;
        case "Well":
          navigate(`/wells?id=${id}`);
          break;
        case "Flowmeter":
          navigate(`/flowmeters?id=${id}`);
          break;
        case "Chemigation":
          navigate(`/chemigations?id=${id}`);
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const highlight = useMemo(() => {
    if (data) {
      return [
        {
          title: "Total Tract Acres",
          value: data.total_acres,
        },
        {
          title: "Reported Corn Yield",
          value: "225 bu / ac",
          percentage: 7,
        },
        {
          title: "Total Pumping",
          value: Number(data.total_pumping?.["2023"] || 0).toFixed(2),
          percentage: Number(
            (data.total_pumping?.["2023"] / data.total_pumping?.["2022"] || 0) *
              100
          ).toFixed(0),
        },
      ];
    }
    return [];
  }, [data]);

  const notifications = (
    <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 p-3">
      <div className="font-bold text-2xl">Messages</div>
      <TableData
        data={messages || []}
        page={page}
        headerItems={
          <>
            <div className="w-1/6 text-center">DATE</div>
            <div className="w-1/2">MESSAGE TITLE</div>
            <div className="w-1/6">TYPE</div>
            <div className="w-1/6 text-center">ACTIONS</div>
          </>
        }
        renderRow={(item, index) => {
          const Envelop = item.read ? EnvelopeOpenIcon : EnvelopeIcon;
          return (
            <div
              className={`flex w-full py-4 cursor-pointer ${
                !item.read ? "font-bold" : ""
              }`}
              onClick={() => handleMessageClick(item)}
            >
              <div className="text-center w-1/6">
                {moment(item.message_date).format("MM-DD-YYYY")}
              </div>
              <div className="w-1/2">{item.message_title}</div>
              <div
                className={`w-1/6 ${
                  item.message_type === "High" ||
                  item.message_type === "Important" ||
                  item.message_type === "Urgent"
                    ? "font-bold"
                    : ""
                }`}
              >
                {item.message_type}{" "}
                {item.message_type === "Urgent" && (
                  <span className="text-red-500">!</span>
                )}
              </div>
              <div className="flex items-center justify-center gap-x-2 w-1/6">
                <Envelop className="w-5 h-5 text-primary" />
                <TrashIcon
                  className="w-5 h-5 text-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMessageToDelete(item);
                  }}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );

  const map = useMemo(() => {
    if (data) {
      const allPoints = [];
      (data.tracts || [])
        .filter((c) => c?.geometry?.coordinates?.length)
        .forEach((c) => {
          if (c.geometry.type === "MultiPolygon") {
            c.geometry.coordinates
              .reduce((acc, [arr]) => [...acc, ...arr], [])
              .forEach((p) => allPoints.push(p));
          } else
            (c.geometry.coordinates[0] || []).forEach((p) => allPoints.push(p));
        });
      [data.wells, data.meters, data.chemigations].forEach((t) =>
        (t || [])
          .filter((el) => el?.geometry?.coordinates)
          .forEach((el) => allPoints.push(el.geometry.coordinates))
      );
      const viewport = calculatePolygonViewport({
        type: "Polygon",
        coordinates: [allPoints],
      }) || {
        latitude: 41.5886072190021,
        longitude: -103.58719705449793,
        zoom: 12.950430835225765,
      };

      const certGeo = convertToFeatureCollection(
        (data.tracts || []).map((item) => ({
          ...item,
          Category: item.category,
          _id: item.label,
        })),
        "label",
        ["Category"],
        "Tract"
      );
      const wellsGeo = convertToFeatureCollection(
        (data.wells || []).map((item) => ({
          ...item,
          _id: item.well_id,
        })),
        "well_id",
        ["Well_Type", "Mon_Active", "Well"]
      );
      const flowmetersGeo = convertToFeatureCollection(
        (data.meters || []).map((item) => ({
          ...item,
          _id: item.meter_id,
        })),
        "meter_id",
        [],
        "Flowmeter"
      );
      const chemigationsGeo = convertToFeatureCollection(
        (data.chemigations || []).map((item) => ({
          ...item,
          _id: item.chem_id,
        })),
        "chem_id",
        [],
        "Chemigation"
      );

      let bboxMap;
      try {
        bboxMap = getBoundBox([
          ...certGeo.features,
          ...wellsGeo.features,
          ...flowmetersGeo.features,
          ...chemigationsGeo.features,
        ]);
      } catch (e) {
        console.warn(e);
      }

      const shouldAddBBox = bboxMap.some(
        (i) => i !== Infinity && i !== -Infinity
      );

      return (
        <div className="w-3/5 aspect-video mx-auto">
          <GenericMap
            certGeo={certGeo}
            wellsGeo={wellsGeo}
            flowmetersGeo={flowmetersGeo}
            chemigationsGeo={chemigationsGeo}
            viewportOverride={viewport}
            expandable
            bboxMap={shouldAddBBox ? bboxMap : undefined}
            onIconClick={handleIconClick}
          />
        </div>
      );
    }
    return null;
  }, [data, handleIconClick]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">Overview</h2>
      {
        <div className="my-6 flex justify-center gap-x-4">
          {highlight.map((item, index) => (
            <HighLight key={index} data={item} />
          ))}
        </div>
      }
      {map}
      {notifications}
      <MessageDetailModal
        open={!!activeMessage}
        data={activeMessage}
        close={() => setActiveMessage(null)}
      />
      <ConfirmModal
        isOpen={!!messageToDelete}
        toggle={() => setMessageToDelete(null)}
        title="Delete"
        message="Are you sure you want to delete this message?"
        onConfirm={handleDeleteMessage}
      />
    </div>
  );
};

export default HomePage;
