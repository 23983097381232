import React from "react";
import Modal from "../common/Modal";
import Button from "../common/Button";

const MessageDetailModalContent = ({ data, close }) => {
  if (!data) {
    return null;
  }
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: data.message_body }} className="innerHtml" />
      <Button className="mx-auto mt-5" onClick={close}>
        Close
      </Button>
    </div>
  );
};

const MessageDetailModal = ({ data, open, close }) => {
  return (
    <Modal
      isOpen={open}
      close={close}
      title={data?.message_title}
      Content={MessageDetailModalContent}
      className="sm:max-w-2xl"
      contentProps={{
        data,
      }}
    />
  );
};

export default MessageDetailModal;
