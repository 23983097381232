import React, { useMemo } from "react";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import useChartWidth from "../../hooks/useChartWidth";
import roundTo from "../../utils/roundTo";
import displayValue from "../../utils/displayValue";

const CustomizedDot = ({ cx, cy, width, height, payload }) => (
  <circle
    r="5"
    stroke="#376eed"
    fill="#fff"
    width={width}
    height={height}
    className="recharts-dot recharts-line-dot"
    cx={cx}
    cy={cy}
  />
);

const FlowmeterUsageChart = ({ units = "Gallons", usageData }) => {
  const width = useChartWidth("flowmeter-usage-chart", window.innerWidth);

  function formatValueTicker(x) {
    if (units === "Gallons") {
      if (x > 1e6) return `${roundTo(x / 1e6, 1)}M`;
      if (x > 1e3) return `${Math.round(x / 1e3)}k`;
    }
    return x;
  }

  const chartData = useMemo(() => {
    const chartData = [];
    Object.keys(usageData).forEach((time) => {
      const date = new Date(time);
      chartData.push({
        Usage: usageData[time],
        dateStr: String(date.getFullYear()),
      });
    });
    return chartData;
  }, [usageData]);

  return (
    <div id="flowmeter-usage-chart" className="w-100">
      <div className="mt-4 ml-4">Acre Inches</div>
      <LineChart
        width={width}
        height={450}
        data={chartData}
        margin={{
          top: 20,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <Line
          type="monotone"
          dataKey="Usage"
          stroke="#396485"
          strokeWidth={2}
          dot={<CustomizedDot />}
          isAnimationActive
        />
        <XAxis
          dataKey="dateStr"
          type="number"
          scale="time"
          tickCount={3}
          tickSize={10}
          domain={["auto", "auto"]}
          tickFormatter={(el) => moment(el).utc(false).format("YYYY")}
        />
        <YAxis tickFormatter={formatValueTicker} />
        <Tooltip
          formatter={(el) => [`${el} Ac-in`]}
          labelFormatter={(el) => displayValue(el, { date: true })}
        />
      </LineChart>
    </div>
  );
};

export default FlowmeterUsageChart;
