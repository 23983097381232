import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import Spinner from "../../components/common/Spinner";

const MapPopupContent = React.lazy(() =>
  import("../../components/map/MapPopupContent")
);
// const MapPopupContentLevels = React.lazy(() =>
//   import("../components/map/MapPopupContentLevels")
// );

// creates a DOM element with the popup's content
export default function createPopupContent({
  type,
  displayId,
  id,
  navigate,
  showLevelsInPopup,
  data,
}) {
  const popupContent = document.createElement("div");

  createRoot(popupContent).render(
    <Suspense fallback={<Spinner />}>
      {showLevelsInPopup ? (
        // <MapPopupContentLevels {...{ id, displayId }} />
        null
      ) : (
        <MapPopupContent
          {...{
            type,
            displayId,
            id,
            navigate,
            data,
          }}
        />
      )}
    </Suspense>
  );

  return popupContent;
}
