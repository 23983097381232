import React, { useMemo, useState } from "react";
import Input from "../common/form/Input";
import { Form, Formik } from "formik";
import Button from "../common/Button";
import NitrogenCostChart from "./NitrogenCostChart";

const CostAnalysis = ({ selectedTract }) => {
  const [nPricePerTon, setNPricePerTon] = useState(338);

  const costPerAcreChartData = useMemo(() => {
    return [
      {
        name: "Recommended N",
        cost: ((selectedTract.nitrate / 2000) * nPricePerTon).toFixed(2),
      },
      {
        name: "Yield Goal N",
        cost: ((selectedTract.required_nitrate / 2000) * nPricePerTon).toFixed(
          2
        ),
      },
    ];
  }, [selectedTract, nPricePerTon]);

  const totalCostChartData = useMemo(() => {
    return [
      {
        name: "Recommended N",
        cost: (
          selectedTract.nitrate *
          (nPricePerTon / 2000) *
          selectedTract.acres
        ).toFixed(2),
      },
      {
        name: "Yield Goal N",
        cost: (
          selectedTract.required_nitrate *
          (nPricePerTon / 2000) *
          selectedTract.acres
        ).toFixed(2),
      },
    ];
  }, [selectedTract, nPricePerTon]);

  return (
    <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 px-3 py-5 flex flex-col xl:flex-row gap-x-3">
      <div className="flex-1">
        <div className="mb-3 font-bold text-xl">Nitrogen Cost Analysis</div>
        <div className="grid grid-cols-3 gap-x-10 gap-y-3 mt-5">
          <div className="col-span-1 justify-end flex items-center">
            N Price Per Ton
          </div>
          <div className="col-span-2">
            <Formik
              initialValues={{
                input: 338,
              }}
              onSubmit={(values) => setNPricePerTon(values.input)}
            >
              <Form className="flex items-center gap-x-4">
                <Input required name="input" suffix="$" className="mt-0" />
                <Button type="submit">Apply</Button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col lg:flex-row mt-5 xl:mt-0">
      <NitrogenCostChart
        id="nitrogen-cost-acre-chart"
        chartData={costPerAcreChartData}
        title="Nitrogen Cost Per Acre"
      />
      <NitrogenCostChart
        id="nitrogen-total-cost-chart"
        chartData={totalCostChartData}
        title="Total Tract Nitrogen Cost"
      />
      </div>
    </div>
  );
};

export default CostAnalysis;
