import React from "react";
import Button from "../common/Button";
import Input from "../common/form/Input";
import DatePicker from "../common/form/DatePicker";
import Select from "../common/form/Select";
import { twMerge } from "tailwind-merge";
import { MinusCircleIcon } from "@heroicons/react/24/solid";

const CropNitrateApplication = ({
  formik,
  config,
  numberApp,
  setNumberApp,
  error,
}) => {
  const tableHeaderClass = "text-center py-2 text-zinc-600 uppercase";
  const tableRowClass = "flex flex-col items-center justify-start p-2";

  const borderColor =
    error && error.includes("all required fields")
      ? "border-red-600"
      : "border-gray-300";

  const handleDeleteRow = (index) => {
    for (let i = index; i < numberApp; i++) {
      config.fields.forEach(({ key }) => {
        const formKey = `${config.prefix}-${i + 1}-${key}`;
        if (formik.values[formKey]) {
          if (i !== index) {
            formik.setFieldValue(
              `${config.prefix}-${i}-${key}`,
              formik.values[formKey]
            );
          }
          formik.setFieldValue(formKey, undefined);
        }
      });
    }
    setNumberApp(numberApp - 1);
  };

  return (
    <div>
      <div className={`rounded-lg mt-5 shadow border ${borderColor}`}>
        <div className="flex bg-gray-100 rounded-t-lg">
          {config.fields.map((item) => (
            <div className={twMerge(tableHeaderClass, item.colSpan)}>
              {item.label}
            </div>
          ))}
        </div>
        {Array(numberApp)
          .fill("")
          .map((item, index) => (
            <div key={index} className={`flex border-t ${borderColor}`}>
              {config.fields.map((field) => {
                const { key, date, dropdown, options } = field;
                const formKey = `${config.prefix}-${index + 1}-${key}`;
                const rowClassName = twMerge(tableRowClass, field.colSpan);
                if (date) {
                  return (
                    <div key={formKey} className={rowClassName}>
                      <DatePicker
                        name={formKey}
                        className="m-0"
                        inputClassName="h-[38px]"
                        value={formik.values[formKey]}
                        onChange={(e) =>
                          formik.setFieldValue(formKey, e.target.value)
                        }
                      />
                    </div>
                  );
                } else if (dropdown) {
                  return (
                    <div key={formKey} className={rowClassName}>
                      <Select
                        extraOptions={options}
                        onChange={(option) => {
                          formik.setFieldValue(formKey, option);
                        }}
                        value={formik.values[formKey]}
                        className="w-full"
                      />
                      {formik.errors[formKey] && (
                        <div className="text-xs text-red-600 mt-1 w-full">
                          {formik.errors[formKey]}
                        </div>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <div key={formKey} className={rowClassName}>
                      <Input
                        name={formKey}
                        inputClassName="text-center px-0 h-[38px]"
                        className="mt-0"
                        value={formik.values[formKey]}
                        onChange={(e) => {
                          formik.setFieldValue(formKey, e.target.value);
                          formik.setFieldTouched(formKey);
                        }}
                      />
                    </div>
                  );
                }
              })}
              <div className={`${tableRowClass} justify-center pl-0`}>
                {index > 0 ? (
                  <MinusCircleIcon
                    className="w-5 h-5 cursor-pointer text-red-600"
                    onClick={() => handleDeleteRow(index)}
                  />
                ) : (
                  <div className="w-5" />
                )}
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-end mt-3">
        <Button
          onClick={(e) => {
            e.preventDefault();
            setNumberApp(numberApp + 1);
          }}
        >
          {config.prefix === "nitrateApp"
            ? "Add Application Event"
            : "Add Application"}
        </Button>
      </div>
    </div>
  );
};

export default CropNitrateApplication;
