const iconUrls = {
  well: "/icons/irrigation_well.png",
  irrWell: "/icons/irrigation_well.png",
  monWell: "/icons/monitoring_well.png",
  miWell: "/icons/municipal_well.png",
  fmGreen: "/icons/flow_meter_full_green.png",
  fmRed: "/icons/flow_meter_full_red.png",
  chemigation: "/icons/chemigation.png",
  soil: "/icons/soil_sample.png",
  soilHighlight: "/icons/soil_sample_select.png",
  meterHighlight: "/icons/flow_meter_select.png",
  wellHighlight: "/icons/irrigation_well_select.png",
  WellHighlight: "/icons/irrigation_well_select.png",
  irrWellHighlight: "/icons/irrigation_well_select.png",
  moWellHighlight: "/icons/monitoring_well_select.png",
  miWelllHighlight: "/icons/municipal_well_select.png",
  chemHighlight: "/icons/chemigation_select.png"
};

export default iconUrls;
