import { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode,
} from 'mapbox-gl-draw-circle';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

mapboxgl.workerClass = MapboxWorker;

const MAPBOX_TOKEN = "pk.eyJ1IjoiaGVhdGgxNDAiLCJhIjoiY2xybDgyMTBvMGkydzJpbzM1bzhqcGRvYSJ9.Hb4t0p1dAA3Hm2GehgHbyg";

// create map on mount, remove on unmount
export default function useCreateMap({ viewportOverride, mapContainer }) {
  const [mapLoaded, setMapLoaded] = useState(false);

  const mapRef = useRef(null);
  const draw = useRef(null);

  const longOverride = viewportOverride && viewportOverride.longitude;
  const latOverride = viewportOverride && viewportOverride.latitude;
  const zoomOverride = viewportOverride && viewportOverride.zoom;
  useEffect(() => {
    let defaultViewport;
    if (!zoomOverride || !latOverride || !longOverride) {
      defaultViewport = {};
    }
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/heath140/clfbfqhe6000701mmoxikeph9',
      zoom: zoomOverride || defaultViewport.zoom,
      center: [
        longOverride || defaultViewport.longitude,
        latOverride || defaultViewport.latitude,
      ],
      accessToken: MAPBOX_TOKEN
    });

    map.on('load', () => {
      setMapLoaded(true);

      // add nav control
      map.addControl(new mapboxgl.NavigationControl({
        visualizePitch: true,
      }));

      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        modes: {
          ...MapboxDraw.modes,
          draw_circle: CircleMode,
          drag_circle: DragCircleMode,
          direct_select: DirectMode,
          simple_select: SimpleSelectMode,
        },
      });
      map.addControl(draw.current, 'bottom-left');
    });

    mapRef.current = map;

    return () => mapRef.current.remove();
    // eslint-disable-next-line
  }, []);

  // resize on container size change
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let resizeTimeout = null;
    function resizeTimeoutCallback() {
      if (mapRef.current && mapLoaded && mapRef.current._canvas) {
        mapRef.current.resize();
      }
    }
    function handleResize() {
      resizeTimeout = setTimeout(resizeTimeoutCallback, 50);
    }
    new ResizeObserver(handleResize).observe(mapContainer.current);
  }, [mapContainer, mapLoaded, mapRef]);

  return { mapRef, draw, mapLoaded };
}
