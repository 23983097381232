import React from "react";
import { twMerge } from "tailwind-merge";

const Loading = ({ className, color = "#658245" }) => {
  return (
    <div
      className={twMerge(
        "w-full h-full flex items-center justify-center",
        className
      )}
    >
      <img src="/PC_Loading.gif" alt="loading-indicator" className="w-[100px] h-[100px]" />
    </div>
  );
};

export default Loading;
