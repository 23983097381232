import { updatePassword } from "aws-amplify/auth";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "../common/Button";

const PasswordSection = () => {
  const [userPass, setUserPass] = useState("");
  const [userNewPass, setUserNewPass] = useState("");
  const [userConfirmPass, setUserConfirmPass] = useState("");
  const [loading, setLoading] = useState(false);

  const inputClassName =
    "block w-full rounded-md border-0 bg-white/5 py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6 dark:bg-neutral-700 dark:text-neutral-200 ring-gray-300";

  const updateUserInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    let validate = true;
    if (userNewPass) {
      if (!userPass) {
        toast.error(
          "You must enter the current password in order to change it."
        );
      } else if (userConfirmPass && userNewPass !== userConfirmPass) {
        toast.error("The passwords do not match.");
        validate = false;
      } else if (userNewPass.length < 8) {
        toast.error("The password must be a minimum of 8 characters long.");
        validate = false;
      } else if (
        !/[a-z]/.test(userNewPass) ||
        !/[A-Z]/.test(userNewPass) ||
        !/[0-9]/.test(userNewPass)
      ) {
        toast.error(
          "The password must contain at least one lowercase letter, one uppercase letter, and one number."
        );
        validate = false;
      }
    } else {
      validate = false;
    }
    if (validate) {
      let success = true;
      if (userNewPass) {
        try {
          const result = await updatePassword({
            oldPassword: userPass,
            newPassword: userNewPass,
          });
          console.log(result);
        } catch (error) {
          console.log(error);
          success = false;
          toast.error("Something went wrong while updating the Password.");
        }
      }
      setLoading(false);
      if (success) {
        setUserPass("");
        setUserNewPass("");
        setUserConfirmPass("");
        toast.success("Your profile has been updated.");
      }
    } else {
      setLoading(false);
      return false;
    }
  };

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7">Change password</h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Update your password associated with your account.
        </p>
      </div>

      <form className="md:col-span-2">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full">
            <label
              htmlFor="current-password"
              className="block text-sm font-medium leading-6"
            >
              Current password
            </label>
            <div className="mt-2">
              <input
                type="password"
                id="userpass"
                name="userpass"
                autoComplete="off"
                className={inputClassName}
                value={userPass}
                onChange={(e) => setUserPass(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="new-password"
              className="block text-sm font-medium leading-6"
            >
              New password
            </label>
            <div className="mt-2">
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                className={inputClassName}
                value={userNewPass}
                onChange={(e) => setUserNewPass(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="confirm-password"
              className="block text-sm font-medium leading-6"
            >
              Confirm password
            </label>
            <div className="mt-2">
              <input
                type="password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                className={inputClassName}
                value={userConfirmPass}
                onChange={(e) => setUserConfirmPass(e.target.value)}
                required
              />
            </div>
          </div>
        </div>

        <div className="mt-8 flex">
          <Button loading={loading} disabled={loading} onClick={updateUserInfo}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PasswordSection;
