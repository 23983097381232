import React, { createContext, useContext, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCurrentUser, fetchNavbar } from "../reducers/userSlice";

const UserContext = createContext();

const UserContextProvider = (props) => {
  const { children, userState, dispatch } = props;

  const userApi = useMemo(
    () => bindActionCreators({ setCurrentUser, fetchNavbar }, dispatch),
    [dispatch]
  );

  const contextValue = useMemo(
    () => [userState, userApi],
    [userApi, userState]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

const mapStateToProps = ({ user }) => ({ userState: user?.currentUser });

export default connect(mapStateToProps)(UserContextProvider);

export const useUserContext = () => useContext(UserContext);
