import React from "react";
import NRDSection from "./NRDSection";
import ThemeSection from "./ThemeSection";

const SettingsPage = () => {
  return (
    <div className="w-full min-h-screen">
      <div className="rounded-lg bg-white dark:bg-gray-900 w-full p-5 pb-10">
        <h2 className="font-bold text-2xl dark:text-white">Settings</h2>
        <div className="divide-y divide-zinc-200">
          <NRDSection />
          <ThemeSection />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
