import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";
import { useUserContext } from "../context/user";
import { useCallback } from "react";

export const useSignIn = () => {
  const [userState, { setCurrentUser }] = useUserContext();

  const getAuthenticatedUser = useCallback(async (callback) => {
    try {
      const session = await fetchAuthSession();
      const attributes = await fetchUserAttributes();

      // get settings
      // store.dispatch(getSettings());

      // sign in on the front end
      const { accessToken } = session.tokens;
      if (!accessToken.payload["cognito:groups"]) {
        accessToken.payload["cognito:groups"] = [];
      }
      const permission =
        accessToken.payload["cognito:groups"].includes("permission");
      const groups = accessToken.payload["cognito:groups"].filter(
        (el) => el !== "permission"
      );

      setCurrentUser({
        ...attributes,
        permission,
        groups,
      });
    } catch (e) {
    } finally {
      if (callback) callback();
    }
  }, [setCurrentUser]);

  return {
    currentUser: userState,
    getAuthenticatedUser,
  };
};