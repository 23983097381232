import React, { useState } from "react";
import Modal from "./Modal";
import { capitalize } from "../../utils/helpers";
import Button from "./Button";
import { FormikProvider, useFormik } from "formik";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import Input from "./form/Input";
import { Alias } from "../../utils/validation";

const AliasModalContent = ({ formik, isLoading }) => {
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="px-4 py-2">
          <Input
            name="alias"
            label="Alias"
            onChange={(e) => {
              formik.setFieldValue("alias", e.target.value);
              formik.setFieldTouched("alias");
            }}
          />
          <div className="flex justify-end">
            <Button type="submit" className="w-fit mt-8" loading={isLoading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

const AliasModal = ({ open, setIsOpen, table, tableId, refreshData }) => {
  const [isLoading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {},
    validationSchema: Alias,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        await request({
          method: "patch",
          url: `/${table}/${tableId}/setAlias`,
          data: values,
        });
        if (refreshData) {
          refreshData();
        }
        toast.success("Successfully updated alias");
        setLoading(null);
        setIsOpen(false);
        resetForm();
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      small
      isOpen={open}
      close={() => {
        setIsOpen(false);
        formik.resetForm();
      }}
      title={`Set ${capitalize(table)} Alias`}
      Content={AliasModalContent}
      contentProps={{
        formik,
        isLoading,
      }}
    />
  );
};

export default AliasModal;
