import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import queryString from 'query-string';
import createPopupContent from './createPopupContent.js';
import useSpiderfier from './useSpiderfier';
import { getDataWithType } from '../../utils/helpers';

export default function useMapUserActions({
  mapRef, viewportCallback,
  certGeom, retirementGeo, showLevelsInPopup, onIconClick
}) {
  const navigate = useNavigate();
  const { spiderifier, customUnspiderfy } = useSpiderfier({ mapRef, showLevelsInPopup });

  const certGeoExists = Boolean(certGeom);
  const retirementGeoExists = Boolean(retirementGeo);
  // handle user actions
  useEffect(() => {
    const map = mapRef.current;

    function handleViewportUpdate() {
      const { transform: { _center: center, _zoom: zoom } } = mapRef.current;
      const { lng: longitude, lat: latitude } = center;
      const bounds = mapRef.current.getBounds();

      if (!bounds) return
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      if (window.location.pathname === '/map') {
        const { ls } = queryString.parse(window.location.search);
        window.history.pushState({}, '', `?${queryString.stringify({
          x: longitude, y: latitude, z: zoom, ls,
        })}`);
      }
      if (viewportCallback) {
        viewportCallback({
          longitude, latitude, zoom,
          neLong: ne.lng,
          neLat: ne.lat,
          swLong: sw.lng,
          swLat: sw.lat
        });
      }
    }

    // fetch new data on move (if relevant)
    map.on('dragend', handleViewportUpdate);
    map.on('zoomend', handleViewportUpdate);

    // show popup on click
    map.on('click', (e) => {
      customUnspiderfy();

      const [feature] = map.queryRenderedFeatures(e.point, {
        layers: [
          ...(certGeoExists ? ['cert_fill'] : []),
          'pointGeom',
          // 'clusters',
          // ...(retirementGeoExists ? ['retirements_fill'] : []),
        ],
      });
      if (!feature) return;
      // click cluster marker
      if (feature.properties.cluster) {
        if (feature.properties.point_count === 2) {
          // spiderify
          map.getSource('pointGeom').getClusterLeaves(
            feature.properties.cluster_id,
            100,
            0,
            (err, leafFeatures) => {
              if (err) console.warn('Couldn\'t get cluster leaves');
              else {
                const markers = leafFeatures.map((f) => f.properties);
                spiderifier.current.spiderfy(
                  feature.geometry.coordinates,
                  markers,
                );
              }
            },
          );
          return
        }

        const clusterId = feature.properties.cluster_id;
        // Ease the camera to the next cluster expansion
        map.getSource('pointGeom').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (!err) {
              map.easeTo({
                center: feature.geometry.coordinates,
                zoom
              });
            }
          }
        );
      }

      // click other marker
      if (!feature.properties?.cluster) {
        // show popup (cert, well, fm)
        const { source, properties: { type, displayId, id, ...others } } = feature;
        if(onIconClick) {
          onIconClick(type, id, e.lngLat);
        } else {
          // const data = getDataWithType(type, others)
          // new mapboxgl.Popup({ offset: 10 })
          //   .setLngLat(e.lngLat)
          //   .setDOMContent(createPopupContent({
          //     type: source === 'certs' ? 'Cert'
          //       : source === 'retirements' ? 'Retirement'
          //         : type,
          //     displayId,
          //     id,
          //     navigate,
          //     showLevelsInPopup,
          //     data
          //   }))
          //   .addTo(map);
        }
      }
    });

    // zoom start
    map.on('zoomstart', function () {
      customUnspiderfy();
    });

    // change cursor - pointer on enter, normal on leave
    function handleMouseEnter() {
      map.getCanvas().style.cursor = 'pointer';
    }
    function handleMouseLeave() {
      map.getCanvas().style.cursor = '';
    }
    ['cert_fill', 'pointGeom']
      .forEach((layer) => {
        map.on('mouseenter', layer, handleMouseEnter);
        map.on('mouseleave', layer, handleMouseLeave);
      });
    // eslint-disable-next-line
  }, [
    mapRef, viewportCallback, navigate,
    certGeoExists, retirementGeoExists, showLevelsInPopup, onIconClick,
  ]);
}
