import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useChartWidth from "../../hooks/useChartWidth";

const NitrogenCostChart = ({ id, chartData, title }) => {
  const width = useChartWidth(id, window.innerWidth);
  return (
    <div id={id} className="flex-1">
      <div className="font-bold mb-3">{title}</div>
      <BarChart
        width={width}
        height={350}
        data={chartData}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={(value) => `$${value}`} />
        <Tooltip formatter={(el) => [`Cost: $${el}`]} />
        <Bar dataKey="cost" fill="#9DC34F">
          {chartData.map((entry, index) => (
            <Cell
              cursor="pointer"
              fill={index === 0 ? "#9DC34F" : "#65A7DD"}
              key={`cell-${index}`}
            />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

export default NitrogenCostChart;
