import React from "react";
import ReactSelect from "react-select";
import useFetch from "../../../hooks/useFetch";
import { isObject } from "formik";

export default function Select({
  value,
  onChange,
  field,
  label,
  extraOptions,
  apiOptions = {},
  below,
  name,
  className,
  ...rest
}) {
  const { data: fetchedOptions } = useFetch(`/dropdownOptions/${field}`, {
    prevent: !field,
    ...apiOptions,
  });

  const options = [];
  if (extraOptions) extraOptions.forEach((el) => options.push(el));
  if (fetchedOptions) fetchedOptions.forEach((el) => options.push(el));

  return (
    <div className={className}>
      {label && (
        <div className="text-sm leading-6 font-medium mb-2 dark:text-white">{label}</div>
      )}
      <ReactSelect
        className="reactSelectContainer"
        classNamePrefix="reactSelect"
        name={name}
        label={label}
        defaultValue={{ label: value, value }}
        onChange={({ value }) => onChange(value)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#658245",
            primary50: "#658245",
            primary25: "#9DC34F",
          }
        })}
        options={options.map((option) =>
          isObject(option)
            ? option
            : {
                label: option,
                value: option,
              }
        )}
        {...rest}
      />
      {below}
    </div>
  );
}
