export const HIGHLIGHTS = [
  {
    title: "Total Tract Acres",
    value: "233 acres",
    percentage: 2.3,
  },
  {
    title: "Reported Corn Yield",
    value: "225 bu / ac",
    percentage: 7,
  },
  {
    title: "Total Pumping",
    value: "136 ac-in",
    percentage: 14,
  },
];

export const NOTIFICATIONS = [
  {
    date: "14/09/2022",
    message: "Water use report posted for 2022 Season",
  },
  {
    date: "15/07/2022",
    message: "Please Report Middle year Usage",
  },
  {
    date: "08/07/2022",
    message: "Chemigation certificate due",
  },
  {
    date: "01/07/2022",
    message: "NRD Producer Meeting 8/15/2022 @ 7:00 PM",
  },
  {
    date: "14/09/2022",
    message: "Water use report posted for 2022 Season",
  },
  {
    date: "15/07/2022",
    message: "Please Report Middle year Usage",
  },
  {
    date: "08/07/2022",
    message: "Chemigation certificate due",
  },
  {
    date: "01/07/2022",
    message: "NRD Producer Meeting 8/15/2022 @ 7:00 PM",
  },
  {
    date: "14/09/2022",
    message: "Water use report posted for 2022 Season",
  },
  {
    date: "15/07/2022",
    message: "Please Report Middle year Usage",
  },
  {
    date: "08/07/2022",
    message: "Chemigation certificate due",
  },
  {
    date: "01/07/2022",
    message: "NRD Producer Meeting 8/15/2022 @ 7:00 PM",
  },
];
