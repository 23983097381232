import { createSlice } from "@reduxjs/toolkit";

const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      if(storedTheme === 'system') {
        const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        return systemPrefersDark ? 'dark' : 'light';
      }
      return storedTheme;
    }
  }
  return 'light';
};

const initialState = {
  loading: false,
  theme: getInitialTheme(),
  isProd: true
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    showLoadingGlobal(state, action) {
      state.loading = action.payload;
    },
    setTheme(state, action) {
      state.theme = action.payload;
    },
    setProd(state, action) {
      state.isProd = action.payload;
    },
  },
});

export const { showLoadingGlobal, setTheme, setProd } = appSlice.actions;

export default appSlice.reducer;
