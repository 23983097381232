import React from "react";
import { Provider } from "react-redux";
import { store } from "../store";
import AppContextProvider from "./app";
import UserContextProvider from "./user";

//TODO: error handling
function AppComposite(props) {
  return (
    <Provider store={store}>
      <AppContextProvider>
        <UserContextProvider>{props.children}</UserContextProvider>
      </AppContextProvider>
    </Provider>
  );
}

export { AppComposite };
