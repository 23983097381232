import React, { useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import request from "../../utils/fetch";
import useFetch from "../../hooks/useFetch";
import { useUserContext } from "../../context/user";
import Button from "../common/Button";
import Select from "../common/form/Select";
import { useAppContext } from "../../context/app";

const getEntityById = (listEntities, id) => {
  return listEntities.find((item) => item.value === id);
};

const getNrdById = (listNrds, id) => {
  return listNrds.find((item) => item.value === id);
};

const NRDSection = () => {
  const [selectedNrd, setSelectedNrd] = useState(undefined);
  const [selectedEntity, setSelectedEntity] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [prevEntity, setPrevEntity] = useState(undefined);

  const [, { fetchNavbar }] = useUserContext();
  const [, { showLoadingGlobal }] = useAppContext();

  const { data: userStatus, refresh: refetchUserStatus } = useFetch("/admin/userStatus");
  const { data: listNrds } = useFetch("/admin/listNrds", {
    prevent: !userStatus || userStatus.user_type !== "Dev",
  });
  const { data: listEntities, refresh: refetchEntities } = useFetch(
    "/admin/listEntities",
    {
      prevent: !userStatus || userStatus.user_type === "User",
    }
  );

  useEffect(() => {
    if (userStatus?.user_nrd) {
      setSelectedNrd(userStatus.user_nrd);
    }
  }, [userStatus]);

  useEffect(() => {
    if (userStatus?.user_entity && listEntities) {
      const findEntity = listEntities.find(
        (item) => item.Email === userStatus.user_entity
      );
      if (findEntity) {
        setPrevEntity(findEntity._id);
        setSelectedEntity(findEntity._id);
      } else {
        setSelectedEntity(listEntities?.[0]._id);
        setPrevEntity(undefined);
      }
    }
  }, [userStatus, listEntities]);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (prevEntity !== selectedEntity) {
        await request({
          method: "put",
          url: "/admin/userEntity",
          data: { newEntity: selectedEntity },
        });
        setPrevEntity(selectedEntity);
      }
      toast.success("Successfully saved setting");
      fetchNavbar();
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNrdChange = async (newNrd) => {
    try {
      showLoadingGlobal(true);
      await request({
        method: "put",
        url: "/admin/userNrd",
        data: { newNrd },
      });
      setSelectedEntity(null);
      setPrevEntity(null);
      refetchEntities();
      refetchUserStatus();
      fetchNavbar();
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      showLoadingGlobal(false);
    }
  };

  if (!userStatus) {
    return <null />;
  }

  const disabled = prevEntity === selectedEntity || isLoading;
  const nrdOptions = (listNrds || []).map((item) => ({
    label: item.display,
    value: item.value,
  }));
  const entityOptions = (listEntities || [])
    .sort((a, b) => (a.nrd_id > b.nrd_id ? 1 : -1))
    .map((item) => ({
      label: `${item.nrd_id} - ${
        item.Company ? item.Company : item.FName + " " + item.LName
      }`,
      value: item._id,
    }));

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 dark:text-white">
          Account Information
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Update your account information
        </p>
      </div>

      <div className="md:col-span-2">
        {userStatus.user_type !== "User" && (
          <div className="flex gap-x-5 mt-5">
            <div className="flex-1">
              {userStatus.user_type !== "NRD" && listNrds && selectedNrd && (
                <Select
                  label="Nrd"
                  defaultValue={getNrdById(nrdOptions, selectedNrd)}
                  onChange={(value) => handleNrdChange(value)}
                  extraOptions={nrdOptions}
                />
              )}
            </div>
            <div className="flex-1">
              {listEntities && selectedEntity && (
                <Select
                  label="Entity"
                  defaultValue={getEntityById(entityOptions, selectedEntity)}
                  onChange={(value) => setSelectedEntity(value)}
                  extraOptions={entityOptions}
                />
              )}
            </div>
          </div>
        )}
        <div className="mt-8 flex">
          <Button onClick={handleSave} disabled={disabled}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NRDSection;
