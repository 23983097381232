import React, { Suspense, useEffect, useState } from "react";
import { userPoolId, userPoolWebClientId } from "../../config/keys";
import { Amplify } from "aws-amplify";
import { useSignIn } from "../../hooks/useSignIn";
import { Toaster } from "react-hot-toast";
import GlobalLoading from "../common/GlobalLoading";

const Sidebar = React.lazy(() => import("./sidebar"));
const SignInPage = React.lazy(() => import("../signin"));

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: userPoolWebClientId,
      userPoolId,
      aws_region: "us-east-2",
    },
  },
});

const Layout = () => {
  const [checkedIfIsSignedIn, setCheckedIfIsSignedIn] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || 'light');

  const { currentUser, getAuthenticatedUser } = useSignIn();

  useEffect(() => {
    const handleThemChanged = () => {
      setTheme(localStorage.getItem("theme"));
    }

    window.addEventListener('storage', handleThemChanged);

    return () => {
      window.removeEventListener('storage');
    }
  }, []);

  useEffect(() => {
    getAuthenticatedUser(() => setCheckedIfIsSignedIn(true));
  }, [getAuthenticatedUser]);

  const loading = (
    <div className="w-screen h-screen flex items-center justify-center dark:bg-gray-900">
      <img
        src="/PC_Loading.gif"
        alt="loading-indicator"
        className="w-[100px] h-[100px]"
      />
    </div>
  );

  return (
    <>
      <Suspense fallback={loading}>
        {checkedIfIsSignedIn ? (
          currentUser && currentUser.email ? (
            <>
              <Sidebar />
              <GlobalLoading />
            </>
          ) : (
            <SignInPage getAuthenticatedUser={getAuthenticatedUser} />
          )
        ) : (
          loading
        )}
      </Suspense>
      <Toaster
        toastOptions={
          theme === "dark"
            ? {
                className: "",
                style: {
                  backgroundColor: "#323045",
                  color: "white",
                },
              }
            : {}
        }
      />
    </>
  );
};

export default Layout;
