import request from "../utils/fetch";

export default async function toDataUrl(url) {
  const data = await request({
    url,
    responseType: "blob",
    apiUrl: null,
  });

  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(data);
    } catch (err) {
      reject(err);
    }
  });
}
