import React, { useRef, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import { scrollToItem } from "../../utils/helpers";

const TableData = ({
  data,
  headerItems,
  handleScrollBottom,
  renderRow,
  page,
  limit = 1000,
  canLoadMore,
  selectedItem,
  enableScrollIntoItem,
}) => {
  const tableRef = useRef(null);

  useEffect(() => {
    if (selectedItem && enableScrollIntoItem) {
      scrollToItem(`item_${selectedItem._id}`);
    }
  }, [enableScrollIntoItem, selectedItem]);

  useEffect(() => {
    const element = tableRef?.current;

    const handleScroll = () => {
      if (element) {
        const isAtBottom =
          element.scrollTop + element.clientHeight >= element.scrollHeight;

        if (isAtBottom && canLoadMore) {
          handleScrollBottom();
        }
      }
    };

    element.addEventListener("scroll", handleScroll);

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScrollBottom, tableRef, canLoadMore]);

  return (
    <div className="mt-5 text-sm">
      <div className="bg-gray-100 dark:bg-gray-600 w-full py-3 flex font-bold">
        {headerItems}
      </div>
      <div className="max-h-[300px] overflow-y-auto" ref={tableRef}>
        {data.slice(0, limit * page).map((item, index) => (
          <div key={index} id={`item_${item._id}`}>
            {renderRow(item, index)}
          </div>
        ))}
        {canLoadMore && (
          <div className="flex justify-center mt-3">
            <PulseLoader color="#658245" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableData;
