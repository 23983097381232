import React, { useCallback, useEffect, useState } from "react";
import Select from "../common/form/Select";
import Button from "../common/Button";
import toast from "react-hot-toast";

const THEME_OPTIONS = {
  automatic: "system",
  dark: "dark",
  light: "light",
};

const ThemeSection = () => {
  const [theme, setTheme] = useState();
  const [prevTheme, setPrevTheme] = useState();

  useEffect(() => {
    const themeConfig = localStorage.getItem("theme") || THEME_OPTIONS.light;
    if (themeConfig) {
      setTheme(themeConfig);
      setPrevTheme(themeConfig);
    }
  }, []);

  const handleSave = useCallback(() => {
    localStorage.setItem("theme", theme);
    setPrevTheme(theme);
    window.dispatchEvent(new Event("storage"));
    toast.success("Successfully saved theme!");
  }, [theme]);

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 dark:text-white">Theme</h2>
        <p className="mt-1 text-sm leading-6 text-gray-400">
          Update theme configurations.
        </p>
      </div>

      <div className="md:col-span-2">
        <div className="w-1/2">
          {theme && (
            <Select
              label="Theme"
              defaultValue={{ label: theme.charAt(0).toUpperCase() + theme.slice(1), value: theme }}
              onChange={(value) => setTheme(value)}
              extraOptions={Object.entries(THEME_OPTIONS).map(
                (item) => ({
                  label: item[1].charAt(0).toUpperCase() + item[1].slice(1),
                  value: item[1]
                })
              )}
            />
          )}
        </div>
        <div className="mt-8 flex">
          <Button onClick={handleSave} disabled={prevTheme === theme}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThemeSection;
