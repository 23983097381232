import React, { useState } from "react";
import Axios from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import "../../assets/scss/MapSearch.scss";
import toast from "react-hot-toast";
import request from "../../utils/fetch";

let sections = [];
Axios(
  "https://opendata.arcgis.com/datasets/e95148e3dbaa4179b1fd3d3a2858cb3a_2.geojson"
).then(({ data }) => {
  sections = data.features;
});

export default function MapSearch({ mapRef }) {
  const [value, setValue] = useState("");
  const [valid, setValid] = useState(false);
  const [redInput, setRedInput] = useState(false);
  const [notFound, setNotFound] = useState(false);

  function handleChange(e) {
    const newVal = e.target.value.toUpperCase();
    setValue(newVal);
    setRedInput(false);
    const parts = newVal.split("-").filter((x) => x);
    let isValid = parts.length === 3;
    if (isValid) {
      if (isNaN(parts[0])) isValid = false;
      const part1NoZeros = parts[1].split("0").join("");
      const part2NoZeros = parts[2].split("0").join("");
      if (
        isNaN(parts[1]) &&
        part1NoZeros !== `${parseInt(part1NoZeros, 10)}N` &&
        part1NoZeros !== `${parseInt(part1NoZeros, 10)}S`
      ) {
        isValid = false;
      }
      if (
        isNaN(parts[2]) &&
        part2NoZeros !== `${parseInt(part2NoZeros, 10)}W` &&
        part2NoZeros !== `${parseInt(part2NoZeros, 10)}E`
      ) {
        isValid = false;
      }
    }
    setValid(isValid);
  }

  async function handleClick() {
    setRedInput(false);

    const parts = value.split("-");

    const section = parseInt(parts[0], 10);
    const township = parseInt(parts[1], 10);
    const townshipDir = isNaN(parts[1])
      ? parts[1].slice(-1).toUpperCase()
      : "N";
    const range = parseInt(parts[2], 10);
    const rangeDir = isNaN(parts[2]) ? parts[2].slice(-1).toUpperCase() : "W";

    try {
      const feature = await request({
        method: "post",
        url: "/layers/sectionCentroid",
        data: {
          Range: range,
          RangeDir: rangeDir,
          Section: section,
          Township: township,
          TownshipDir: townshipDir,
        },
      });
      if (feature?.coordinates) {
        let { coordinates } = feature;
        if (feature.type === "MultiPolygon") [coordinates] = coordinates;
        // coordinates = coordinates.slice(1);
        const [avgLong, avgLat] = coordinates;
        mapRef.current.flyTo({ center: [avgLong, avgLat], zoom: 12 });
      } else {
        setNotFound(true);
        setTimeout(() => setNotFound(false), 3000);
      }
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      if (valid) handleClick();
      else setRedInput(true);
    }
  }

  return (
    <div className="MapSearch">
      <div className="border flex items-center flex-row-reverse">
        <button
          color="gray"
          className="px-1 py-2 my-0 iconButton w-7 cursor-pointer"
          onClick={handleClick}
          disabled={!valid}
        >
          <MagnifyingGlassIcon />
        </button>
        <div className="flex-1">
          <input
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Search PLSS"
            hint="(Eg. 6-21N-56W)"
            className={`${
              redInput ? "error" : ""
            } border-none w-full focus:border-transparent focus:ring-0`}
          />
          {notFound && <p className="red-text">Section not found</p>}
        </div>
      </div>
    </div>
  );
}
