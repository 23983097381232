import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import Axios from "axios";
import queryString from "query-string";
import turfArea from "@turf/area";
import GenericMapLeftMenu from "./GenericMapLeftMenu";
import MapSearch from "./MapSearch";
import useMap from "../../hooks/map/useMap";
// import RootItemContext from "../../context/RootItemContext";
import roundTo from "../../utils/roundTo";
import "../../assets/scss/MapboxGL.scss";
import "../../assets/scss/GenericMap.scss";
import shouldHideSoils from "../../config/shouldHideSoils";
import SoilLocationButton from "./SoilLocationButton";
// eslint-disable-next-line
mapboxgl.workerClass =
  // eslint-disable-next-line
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const area = (geojson) => roundTo(turfArea(geojson) / 4046.86, 1);
// mapboxgl.accessToken = 'pk.eyJ1IjoiaGVhdGgxNDAiLCJhIjoiY2xmYmU3M2ZhMnBuMTNzbzQ1MG83dGltcSJ9.Ib1iITdUCMOl8YitmV77NA';

export default function GenericMap({
  certGeo,
  irrigatedGeo,
  wellsGeo,
  flowmetersGeo,
  chemigationsGeo,
  soilsGeo,
  retirementGeo,
  viewportOverride,
  viewportCallback,
  layersOverride = [
    "Certified Acres",
    "Non-Certified Acres",
    "Dryland",
    "Surface Water Only",
    "irrWells",
    "monWells",
    "miWells",
    "wells",
    "flowmeters",
    "chemigations",
    shouldHideSoils ? [] : ["soils"],
    "plss",
    "roads",
  ],
  style,
  alwaysShowIcons,
  layerSwitch,
  editingEndpoint,
  showLevelsInPopup,
  wur,
  labelWellsWithRegNo,
  bboxMap = null,
  shouldAddNRDBoundaries = false,
  onIconClick,
  iconSize,
  showSoilLocationButton = false,
  refreshSoilData,
  bboxPadding = 20,
  displayTownships = false,
  searchable
}) {
  //   const { refresh: refreshItem } = useContext(RootItemContext);

  const [shownLayers, setShownLayers] = useState(
    layersOverride.reduce((acc, key) => ({ ...acc, [key]: true }), {})
  );

  const [showLayersController, setShowLayersController] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(false);

  const [polygonArea, setPolygonArea] = useState(null);
  const [editTarget, setEditTarget] = useState(null);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");

  const mapContainer = useRef(null);

  const { mapRef, draw, mapLoaded } = useMap({
    viewportOverride,
    viewportCallback,
    mapContainer,
    certGeo,
    irrigatedGeo,
    retirementGeo,
    wellsGeo,
    flowmetersGeo,
    chemigationsGeo,
    soilsGeo,
    shownLayers,
    layersOverride,
    alwaysShowIcons,
    wur,
    showLevelsInPopup,
    labelWellsWithRegNo,
    shouldAddNRDBoundaries,
    onIconClick,
    iconSize,
    displayTownships
  });

  // update the area of polygon when editing
  useEffect(() => {
    function updateArea() {
      const data = draw.current.getAll();
      setPolygonArea(data.features.length ? area(data) : null);
    }

    const map = mapRef.current;
    map.on("draw.create", updateArea);
    map.on("draw.delete", updateArea);
    map.on("draw.update", updateArea);
    map.on("mousemove", (e) => {
      setLat(e.lngLat.lat);
      setLon(e.lngLat.lng);
    });
  }, [mapRef, draw]);

  useEffect(() => {
    if (mapRef.current && mapLoaded && bboxMap) {
      mapRef.current.fitBounds(bboxMap, { padding: bboxPadding, maxZoom: 15 });
    }
    // eslint-disable-next-line
  }, [bboxMap, mapLoaded]);

  const editingPolygon = () =>
    editingEndpoint === "certs" || editingEndpoint === "retirements";

  function handleLayerSwitchChange(key) {
    const map = mapRef.current;
    let layersToFlip = [];
    switch (key) {
      case "plss":
        layersToFlip = [
          "Sections",
          "Sections_label",
          "Townships",
          "Townships_label",
        ];
        break;
      case "roads":
        layersToFlip = [
          "highways",
          "highways_label",
          "streetCenterlines",
          "streetCenterlines_label",
        ];
        break;
      default:
    }
    layersToFlip.forEach((layer) => {
      map.setLayoutProperty(
        layer,
        "visibility",
        shownLayers[key] ? "none" : "visible"
      );
    });

    // for wells and FMs, visibility is modified by changing pointGeom

    const newShownLayers = { ...shownLayers, [key]: !shownLayers[key] };
    setShownLayers(newShownLayers);
    if (window.location.pathname === "/map") {
      const { x, y, z } = queryString.parse(window.location.search);
      const ls = Object.entries(newShownLayers)
        .filter((entry) => entry[1])
        .map(([key]) => key)
        .join(",");
      window.history.pushState(
        {},
        "",
        `?${queryString.stringify({
          x,
          y,
          z,
          ls,
        })}`
      );
    }
  }

  function handleExpandedChange() {
    const map = mapRef.current;
    setExpanded((x) => !x);

    setTimeout(() => {
      map.resize();
      let zoom = map.getZoom();
      if (expanded) zoom -= 2.75;
      else zoom += 2.75;
      map.easeTo({ zoom });
    });
  }

  function handleEditingChange(newEditTarget) {
    const map = mapRef.current;

    [
      "cert_fill",
      "cert_line",
      "cert_symbol",
      "irrigated_fill",
      "irrigated_line",
      "irrigated_symbol",
      "clusters",
      "clusterCounters",
      "pointGeom",
      "retirements_fill",
      "retirements_line",
      "retirements_symbol",
    ].forEach((layer) => {
      map.setLayoutProperty(layer, "visibility", editing ? "visible" : "none");
    });

    if (editing) {
      draw.current.deleteAll();
      map.getCanvas().style.cursor = "";
      setEditTarget(null);
    } else {
      let { _data: data } = map.getSource(
        editingPolygon() ? newEditTarget || editingEndpoint : "pointGeom"
      );
      if (data.type === "FeatureCollection") [data] = data.features;
      const { properties } = data;
      const features = [];
      if (data.geometry) {
        if (data.geometry.type.includes("Multi")) {
          data.geometry.coordinates.forEach((coordinates) => {
            features.push({
              type: "Feature",
              geometry: {
                type: editingPolygon() ? "Polygon" : "Point",
                coordinates,
              },
              properties,
            });
          });
        } else features.push(data);
      }

      const featureCollection = {
        type: "FeatureCollection",
        features,
      };

      draw.current.set(featureCollection);

      setPolygonArea(features.length ? area(featureCollection) : null);
      setEditTarget(newEditTarget);

      map.on("draw.modechange", ({ mode }) => {
        if (mode.includes("select")) map.getCanvas().style.cursor = "";
      });
    }

    setEditing((x) => !x);
  }

  async function handleSave() {
    const { features } = draw.current.getAll();

    const featuresEmpty = !features.length;

    const geometry = featuresEmpty
      ? null
      : {
          type: editingPolygon()
            ? features.length > 1
              ? "MultiPolygon"
              : "Polygon"
            : "Point",
        };
    if (!featuresEmpty) {
      const coordinates = features.map(({ geometry }) => geometry.coordinates);
      geometry.coordinates = features.length > 1 ? coordinates : coordinates[0];
    }

    let id = features[0]?.properties?.id;

    if (!id) {
      switch (editingEndpoint) {
        case "certs":
          id = certGeo?.properties?.id;
          break;
        case "wells":
          id = wellsGeo?.properties?.id;
          break;
        case "flowmeters":
          id = flowmetersGeo?.properties?.id;
          break;
        case "chemigations":
          id = chemigationsGeo?.properties?.id;
          break;
        default:
          id = retirementGeo?.properties?.id;
          break;
      }
    }

    const endpoint2 = editTarget === "irrigated" ? "irrigatedGeom" : "geom";

    await Axios.post(`${editingEndpoint}/${id}/${endpoint2}`, { geometry });
    // refreshItem();
    setExpanded(false);

    handleEditingChange();
  }

  return (
    <div
      className={`GenericMap leaflet-control-container leaflet-touch ${
        expanded ? " expanded" : ""
      }`}
    >
      <div
        ref={(el) => (mapContainer.current = el)}
        className="map"
        style={style}
      />
      {layerSwitch && (
        <div
          className="leaflet-left"
          style={{ position: "absolute", top: "0", zIndex: "999" }}
        >
          <div
            className={`leaflet-control-layers leaflet-control ${
              mapLoaded && showLayersController
                ? "leaflet-control-layers-expanded"
                : ""
            }`}
            style={{ marginTop: "10px", marginLeft: "10px" }}
            onMouseEnter={() => setShowLayersController(true)}
            onMouseLeave={() => setShowLayersController(false)}
          >
            <div className="leaflet-control-layers-toggle" title="Layers" />
            <form className="leaflet-control-layers-list">
              <div className="leaflet-control-layers-overlays">
                {[
                  {
                    key: "certs_group",
                    label: "Tracts",
                    child: [
                      { key: "Certified Acres", label: "Certified Acres" },
                      {
                        key: "Non-Certified Acres",
                        label: "Non-Certified Acres",
                      },
                      { key: "Dryland", label: "Dryland" }, // Municipal and Industrial
                      {
                        key: "Surface Water Only",
                        label: "Surface Water Only",
                      },
                    ],
                  },
                  {
                    key: "wells_group",
                    label: "Wells",
                    child: [
                      { key: "irrWells", label: "Irrigation Wells" },
                      { key: "monWells", label: "Monitoring Wells" },
                      { key: "miWells", label: "M&I Wells" }, // Municipal and Industrial
                      { key: "wells", label: "Other Wells" },
                    ],
                  },
                  { key: "flowmeters", label: "Flowmeters" },
                  { key: "chemigations", label: "Chemigations" },
                  ...(shouldHideSoils
                    ? []
                    : [{ key: "soils", label: "Soil Samples" }]),
                  { key: "plss", label: "PLSS" },
                ].map(({ key, label, child }) => (
                  <div
                    className="leaflet-control-layers-group"
                    key={`group_${key}`}
                  >
                    {child && (
                      <label className="leaflet-control-layers-group-label">
                        <span className="leaflet-control-layers-group-name">
                          {label}
                        </span>
                      </label>
                    )}
                    {child &&
                      child.map((e) => (
                        <label
                          className="leaflet-control-layers-label leaflet-control-layers-child"
                          key={`group_${e.key}`}
                        >
                          <input
                            className="leaflet-control-layers-selector"
                            id={`layer_switch_${e.key}`}
                            key={e.key}
                            label={e.label}
                            type="checkbox"
                            checked={shownLayers[e.key]}
                            onChange={() => handleLayerSwitchChange(e.key)}
                          />
                          <span>{e.label}</span>
                        </label>
                      ))}
                    {!child && (
                      <label className="leaflet-control-layers-label">
                        <input
                          className="leaflet-control-layers-selector"
                          id={`layer_switch_${key}`}
                          key={key}
                          label={label}
                          type="checkbox"
                          checked={shownLayers[key]}
                          onChange={() => handleLayerSwitchChange(key)}
                        />
                        <span>{label}</span>
                      </label>
                    )}
                  </div>
                ))}
              </div>
            </form>
          </div>
        </div>
      )}
      {lat && lon && (
        <div className="coordinate-section">
          {lon.toFixed(7)} | {lat.toFixed(7)}
        </div>
      )}
      {/* {(expandable || editable) && (
        <GenericMapLeftMenu
          expandable={expandable}
          editable={editable}
          isPolyonEdit={editingPolygon()}
          irrigatedEditable={irrigatedEditable}
          expanded={expanded}
          onExpandedChange={handleExpandedChange}
          editing={editing}
          onEditingChange={handleEditingChange}
          mapRefVal={mapRef.current}
          drawRefVal={draw.current}
          onSave={handleSave}
        />
      )} */}
      {searchable && <MapSearch mapRef={mapRef} />}
      {showSoilLocationButton && (
        <SoilLocationButton
          refreshSoilData={refreshSoilData}
          hasSoil={soilsGeo?.features?.length}
        />
      )}
      {expanded && (
        <div className="map-expand-overflow" onClick={handleExpandedChange} />
      )}
      <div className="area-container">
        <div className={`area${editing && polygonArea ? " open" : ""}`}>
          {polygonArea} acres
        </div>
      </div>
    </div>
  );
}
