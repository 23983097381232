import bboxTurf from '@turf/bbox';

const getBoundBox = (features) => {
  if (!features) return null;

  const featureCollection = {
    type: 'FeatureCollection',
    features: features,
  };

  return bboxTurf(featureCollection);
};

export {
  getBoundBox
};