import React, { useMemo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import GenericMap from "../map/GenericMap";
import HighLight from "../highlight";
import TableData from "../table";
import useFetch from "../../hooks/useFetch";
import Loading from "../common/Loading";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";
import { getBoundBox } from "../../utils/turfHelpers";
import AliasModal from "../common/AliasModal";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import TraySlideOut from "../common/TraySlideOut";
import ActivityIcon from "../common/ActivityIcon";
import Button from "../common/Button";
import { pointsWithinPolygon } from "@turf/points-within-polygon";

const WellPage = () => {
  const [isLoading, setShowLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [showAliasModal, setShowAliasModal] = useState(null);
  const [openTray, setOpenTray] = useState(false);
  let [searchParams] = useSearchParams();

  const { data, refresh } = useFetch("/wells");
  const { data: tractData } = useFetch("/tracts");

  useEffect(() => {
    if (data) {
      setShowLoading(false);
      if (data.well_list?.length && !selectedItem) {
        let defaultItem = data.well_list[0];
        if (searchParams.get("id")) {
          const defaultSelectedWell = data.well_list.find(
            (item) =>
              item._id === searchParams.get("id") ||
              item.NRD_ID === searchParams.get("id")
          );
          if (defaultSelectedWell) {
            defaultItem = defaultSelectedWell;
          }
        }
        setSelectedItem(defaultItem);
      }
    }
  }, [data, searchParams, selectedItem]);

  const highlight = useMemo(() => {
    if (data) {
      return [
        {
          title: "Total Wells",
          value: data.total_wells,
        },
        {
          title: "Total GPM",
          value: data.total_gpm,
        },
        {
          title: "Total Monitoring Wells",
          value: data.total_mon_wells,
        },
      ];
    }
    return null;
  }, [data]);

  const map = useMemo(() => {
    if (!data) {
      return null;
    }
    let certGeo = null;
    const allPoints = [];
    (data.well_list || [])
      .filter((el) => el?.geometry?.coordinates)
      .forEach((el) => allPoints.push(el.geometry.coordinates));

    if (tractData) {
      certGeo = convertToFeatureCollection(tractData.TractsDetail, "Cert_ID", [
        "Category",
      ]);
    }

    const viewport = calculatePolygonViewport({
      type: "Polygon",
      coordinates: [allPoints],
    }) || {
      latitude: 41.5886072190021,
      longitude: -103.58719705449793,
      zoom: 12.950430835225765,
    };

    const wellGeo = convertToFeatureCollection(data.well_list, "NRD_ID", [
      "Well_Type",
      "Mon_Active",
    ]);

    let selectedGeo;
    if (selectedItem) {
      (wellGeo?.features || []).forEach((item) => {
        if (item.properties.id === selectedItem._id) {
          item.properties.isSelected = true;
          selectedGeo = item;
        }
      });

      (certGeo?.features || []).forEach(item => {
        if(pointsWithinPolygon(selectedGeo, item)?.features?.length) {
          item.properties.isSelected = true;
        }
      });
    }

    let bboxMap;
    try {
      bboxMap = getBoundBox(
        selectedGeo
          ? [selectedGeo]
          : [...wellGeo.features, ...(certGeo?.features || [])]
      );
    } catch (e) {
      console.warn(e);
    }

    const shouldAddBBox = bboxMap?.some(
      (i) => i !== Infinity && i !== -Infinity
    );

    return (
      <GenericMap
        wellsGeo={wellGeo}
        certGeo={certGeo}
        viewportOverride={viewport}
        expandable
        bboxMap={shouldAddBBox ? bboxMap : null}
        onIconClick={(type, id) => {
          const newSelectedWell = (data.well_list || []).find(
            (item) => item._id === id
          );
          if (newSelectedWell) {
            setSelectedItem(newSelectedWell);
          }
        }}
      />
    );
  }, [data, selectedItem, tractData]);

  if (isLoading) {
    return <Loading />;
  }

  const headerClassName = "flex-1 justify-evenly px-1";
  const rowClassName = "flex-1 justify-evenly px-1";

  const renderItem = ({ label, key, suffix, bool }) => {
    let valueToShow = selectedItem?.[key];
    if (bool) {
      if (key === 'Active'){
        return (
          <div key={key} className="sm:col-span-1">
            <label className="font-semibold text-sm">{label}: </label>
            <div className="mt-1 text-sm">
              <ActivityIcon 
                active={valueToShow} 
                pill 
              />
            </div>
          </div>
        )
      }}
      if (key === 'Mon_Active'){
        return (
          <div key={key} className="sm:col-span-1">
            <label className="font-semibold text-sm">{label}: </label>
            <div className="mt-1 text-sm">
              <ActivityIcon 
                active={valueToShow} 
                pill 
                onText="Monitoring Active"
                offText="Not Monitoring"
              />
            </div>
          </div>
        )
        
      }
    if (key === 'DNR_Well_ID') {
      return (
        <div key={key} className="sm:col-span-1">
        <label className="font-semibold text-sm">{label}: </label>
        <div className="mt-1 text-sm">
          {valueToShow ? (
          <a
            href={`https://nednr.nebraska.gov/Dynamic/Wells/Wells/WellDetails?WellId=${valueToShow}`}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            {valueToShow || "N/A"} {suffix || ""}
          </a>
          ) : (
            "N/A"
          )}
        </div>
      </div>
      )
    }
    return (
      <div key={key} className="sm:col-span-1">
        <label className="font-semibold text-sm">{label}: </label>
        <div className="mt-1 text-sm">
          {valueToShow || "N/A"} {suffix || ""}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">Wells Overview</h2>
      <div className="my-6 flex gap-x-4">
        {highlight &&
          highlight.map((item, index) => (
            <HighLight key={index} data={item} className="w-full" />
          ))}
      </div>
      <div className="flex my-10 gap-x-5 items-start ">
        <div className="flex-1 h-[472px]">{map}</div>
        <div className="flex-1">
          {data && (
            <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full p-3">
              <TableData
                enableScrollIntoItem
                selectedItem={selectedItem}
                data={data.well_list || []}
                page={1}
                headerItems={
                  <>
                    <div className={headerClassName}>ALIAS</div>
                    <div className={headerClassName}>Reg No</div>
                    <div className={headerClassName}>Status</div>
                    <div className={headerClassName}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  </>
                }
                renderRow={(item, index) => (
                  <div
                    className={`flex full py-4 cursor-pointer ${
                      selectedItem?._id === item._id ? "bg-gray-1" : ""
                    }`}
                    onClick={() => {
                      setSelectedItem(item);
                    }}
                  >
                    <div className={rowClassName}>
                      {item.Alias}
                      <PencilSquareIcon
                        className="w-5 h-5 cursor-pointer ml-1  "
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAliasModal(item._id);
                        }}
                      />
                    </div>
                    <div className={rowClassName}>{item.Reg_No}</div>
                    <div className={rowClassName} >
                      <div className="pr">
                        <ActivityIcon 
                          active={item.Active} 
                          pill 
                        />
                      </div>
                      <div>
                      {item.Mon_Active && (
                        <ActivityIcon 
                          active={item.Mon_Active} 
                          pill 
                          onText="Monitoring Active"
                        />
                      )}
                      </div>
                    </div>
                    <div className={rowClassName}>
                      <div className="pl-20 justify-end whitespace-nowrap">
                        <Button
                          onClick={() => {
                            setSelectedItem(item);
                            setOpenTray(true);
                          }}
                        >
                          More Info
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          )}
        </div>
      </div>
      <AliasModal
        open={!!showAliasModal}
        setIsOpen={setShowAliasModal}
        table="wells"
        tableId={showAliasModal}
        refreshData={refresh}
      />
      <TraySlideOut
        title="Well Information"
        onClose={() => {
          setTimeout(() => setOpenTray(false), 100);
        }}
        open={openTray}
      >
        <div className="p-6">
          {isLoading ? (
            <Loading />
          ) : data ? (
            <div className="w-100 grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-6 first:mt-0 last:mb-0">
              {[
              { key: "NRD_ID", label: "Well ID" },
              { key: "alias", label: "Alias" },
              { key: "Well_Type", label: "Type" },
              { key: "Active", label: "Active", bool: true },
              { key: "sub_area", label: "Sub-Area" },
              { key: "phase_area", label: "Phase Area" },
              { key: "DNR_Well_ID", label: "DNR ID" },
              { key: "Mon_Active", label: "Monitored Well", bool: true },
              { key: "Legal", label: "Legal" },
            ].map(renderItem)}
            </div>
          ) : null }
        </div>
      </TraySlideOut>
    </div>
  );
};

export default WellPage;
