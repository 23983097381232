import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';

export default function ConfirmModal({
  isOpen,
  toggle,
  title,
  message,
  disabled,
  onConfirm,
  confirmButtonLabel = 'Yes',
  extraButton,
  extraButtonLabel,
  onExtraButtonClick,
}) {
  const [uiIsOpen, setUiIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      onConfirm();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setUiIsOpen(isOpen);
      setIsSubmitting(false);
    }, 250);
  }, [isOpen]);

  useEffect(() => {
    function handleKeyPress(e) {
      if (uiIsOpen && e.key === 'Enter') handleConfirm();
    }
    document.body.addEventListener('keypress', handleKeyPress);
    return () => document.body.removeEventListener('keypress', handleKeyPress);
  }, [uiIsOpen, handleConfirm]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={toggle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full ${extraButton ? 'sm:max-w-xl' : 'sm:max-w-lg'} sm:p-6`}>
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{message}</p>
                    </div>
                  </div>
                </div>
                <div className={`mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense ${extraButton ? 'sm:grid-cols-3' : 'sm:grid-cols-2'} sm:gap-3`}>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={toggle}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary sm:col-start-2"
                    onClick={handleConfirm}
                    disabled={disabled}
                  >
                    {confirmButtonLabel}
                  </button>
                  {extraButton && (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-100 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3"
                      onClick={onExtraButtonClick}
                      disabled={disabled}
                    >
                      {extraButtonLabel}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
