import React from "react";

const CustomizedDot = ({ cx, cy, width, height, payload }) => (
    <circle
      r="5"
      stroke="#376eed"
      fill="#fff"
      width={width}
      height={height}
      className="recharts-dot recharts-line-dot"
      cx={cx}
      cy={cy}
    />
  );

export default CustomizedDot;