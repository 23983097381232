import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import MapboxSpiderifier from 'mapboxgl-spiderifier';

import iconUrls from './iconUrls';
import createPopupContent from './createPopupContent.js';
import { getDataWithType } from '../../utils/helpers';

export default function useSpiderfier({ mapRef, showLevelsInPopup }) {
  const navigate = useNavigate();

  const spiderifier = useRef(null);
  const popupRef = useRef(null);

  // set up spiderifier on load
  useEffect(() => {
    spiderifier.current = new MapboxSpiderifier(mapRef.current, {
      customPin: true,
      animate: true,
      initializeLeg: (spiderLeg) => {
        const { pin } = spiderLeg.elements;
        const {
          iconType, type, displayId, id, ...others
        } = spiderLeg.feature;
        const { mapboxMarker } = spiderLeg;
        const data = getDataWithType(type, others)

        pin.innerHTML = `<img src="${iconUrls[iconType]}" alt="${type}">`;
        pin.style.cursor = 'pointer';
        pin.style.marginLeft = '-5px';
        pin.style.marginTo = '-5px';

        pin.addEventListener('click', (e) => {
          e.stopPropagation();

          const popup = new mapboxgl.Popup({
            offset: MapboxSpiderifier.popupOffsetForSpiderLeg(spiderLeg),
          })
            .setDOMContent(createPopupContent({
              type,
              displayId,
              id,
              navigate,
              showLevelsInPopup,
              data
            }));

          mapboxMarker.setPopup(popup);

          popupRef.current = popup;

          popup.addTo(mapRef.current);
        });
      },
    });
  });

  const customUnspiderfy = () => {
    const spiderfyContainers = document.querySelectorAll(".spider-leg-container")

    if (spiderfyContainers) {
      spiderfyContainers.forEach(container => {
        if (container.hasOwnProperty('remove')) {
          return;
        }
        Object.defineProperty(container, 'remove', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function remove() {
            this.parentNode.removeChild(this);
          }
        });
        container.remove();
      })

      if (popupRef.current) {
        popupRef.current._onClose();
      }
    }
  }

  return {
    spiderifier,
    customUnspiderfy
  };
}
