import { useEffect } from "react";
import iconUrls from "./iconUrls";
import { MARKER_MIN_ZOOM } from "../../utils/constants";

const emptyCollection = {
  type: "FeatureCollection",
  features: [],
};

// promisified map.loadImage
function loadImage(map, type) {
  return new Promise((resolve, reject) => {
    map.loadImage(iconUrls[type], (err, image) => {
      if (err) reject(err);
      map.addImage(type, image);
      resolve();
    });
  });
}

// set up layers on load
export default function useMapLayersSetup({
  mapRef,
  certGeom,
  irrigatedGeo,
  retirementGeo,
  pointGeom,
  layersOverride,
  alwaysShowIcons,
  wur,
  labelWellsWithRegNo,
  shouldAddNRDBoundaries,
  iconSize,
  townshipsGeo,
  sectionsGeo,
}) {
  useEffect(() => {
    const map = mapRef.current;

    // if the map has already loaded, no need to add another on load event
    if (map.getSource("NRDs")) return;

    map.on("load", () => {
      if (!wur) {
        // show the NRDs, PLSS units, highways and street centerlines in NE
        if (!map.getSource("Sections")) {
          map.addSource("Sections", {
            type: "geojson",
            data: sectionsGeo || emptyCollection,
          });
          map.addLayer({
            id: "Sections",
            source: "Sections",
            type: "line",
            minzoom: 10,
            layout: {
              visibility: layersOverride.includes("plss") ? "visible" : "none",
            },
            paint: {
              "line-color": "#ddd",
              "line-width": ["interpolate", ["linear"], ["zoom"], 10, 1, 15, 5],
            },
          });
          map.addLayer({
            id: "Sections_label",
            type: "symbol",
            source: "Sections",
            layout: {
              "text-field": "{displayId}",
              visibility: layersOverride.includes("plss") ? "visible" : "none",
            },
            paint: {
              "text-color": "#eee",
            },
            minzoom: 11.5,
          });
        }
        if (!map.getSource("Townships")) {
          map.addSource("Townships", {
            type: "geojson",
            data: townshipsGeo || emptyCollection,
          });
          map.addLayer({
            id: "Townships",
            source: "Townships",
            type: "line",
            minzoom: 7,
            layout: {
              visibility: layersOverride.includes("plss") ? "visible" : "none",
            },
            paint: {
              "line-color": "#aaa",
              "line-width": ["interpolate", ["linear"], ["zoom"], 7, 1, 15, 7],
            },
          });
          map.addLayer({
            id: "Townships_label",
            type: "symbol",
            source: "Townships",
            layout: {
              "text-field": "{displayId}",
              visibility: layersOverride.includes("plss") ? "visible" : "none",
            },
            paint: {
              "text-color": "#eee",
            },
            minzoom: 8.5,
            maxzoom: 11.5,
          });
        }
        if (!map.getSource("NRDs")) {
          if (shouldAddNRDBoundaries) {
            map.addSource("NRDs", {
              type: "geojson",
              data: "https://opendata.arcgis.com/datasets/87194256e6da455993e785854af58470_10.geojson",
            });
            map.addLayer({
              id: "NRDs",
              source: "NRDs",
              type: "line",
              minzoom: 4,
              paint: {
                "line-color": "#ccc",
                "line-width": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  4,
                  1,
                  15,
                  10,
                ],
              },
            });
          }
        }
        /******** Remove roads layers
        if (!map.getSource('highways')) {
          map.addSource('highways', {
            type: 'geojson',
            data: 'https://opendata.arcgis.com/datasets/190f9fabe7e6420bb1bb418f72d34715_4.geojson',
          });
          map.addLayer({
            id: 'highways',
            source: 'highways',
            type: 'line',
            minzoom: 4,
            layout: {
              visibility: layersOverride.includes('roads') ? 'visible' : 'none',
            },
            paint: {
              'line-color': '#f0b337',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                5, 1,
                20, 10,
              ],
            },
          });
          map.addLayer({
            id: 'highways_label',
            type: 'symbol',
            source: 'highways',
            layout: {
              'text-field': '{HwyLabel}',
              visibility: layersOverride.includes('roads') ? 'visible' : 'none',
            },
            paint: {
              'text-color': '#fff',
            },
            minzoom: 6,
          });
        }
        if (!map.getSource('streetCenterlines')) {
          map.addSource('streetCenterlines', {
            type: 'geojson',
            data: 'https://opendata.arcgis.com/datasets/683ee8988c4a42deb19b97b78d001cef_3.geojson',
          });
          map.addLayer({
            id: 'streetCenterlines',
            source: 'streetCenterlines',
            type: 'line',
            minzoom: 10,
            layout: {
              visibility: layersOverride.includes('roads') ? 'visible' : 'none',
            },
            paint: {
              'line-color': '#e3c727',
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                10, 1,
                20, 6,
              ],
            },
          });
          map.addLayer({
            id: 'streetCenterlines_label',
            type: 'symbol',
            source: 'streetCenterlines',
            layout: {
              'text-field': '{STNAME}',
              visibility: layersOverride.includes('roads') ? 'visible' : 'none',
            },
            paint: {
              'text-color': '#fff',
            },
            minzoom: 13,
          });
        }
        ***********/
      }

      // add cert geom
      if (!map.getSource("certs")) {
        map.addSource("certs", {
          type: "geojson",
          data: certGeom || emptyCollection,
        });
        map.addLayer({
          id: "cert_fill",
          type: "fill",
          source: "certs",
          paint: {
            "fill-color": ["get", "bgcolor"],
            "fill-opacity": 0.4,
          },
          minzoom: 8,
        });
        map.addLayer({
          id: "cert_line",
          type: "line",
          source: "certs",
          paint: {
            "line-color": ["get", "borderColor"],
            "line-width": 2,
          },
          minzoom: 11.5,
        });
        map.addLayer({
          id: "cert_symbol",
          type: "symbol",
          source: "certs",
          layout: {
            "text-field": ["get", "displayId"],
          },
          paint: {
            "text-color": "#000000",
          },
        });
      }
      if (!map.getSource("irrigated")) {
        map.addSource("irrigated", {
          type: "geojson",
          data: irrigatedGeo || emptyCollection,
        });
        map.addLayer({
          id: "irrigated_fill",
          type: "fill",
          source: "irrigated",
          layout: {
            visibility: layersOverride.includes("certs") ? "visible" : "none",
          },
          paint: {
            "fill-color": "#8aeb91",
            "fill-opacity": 0.4,
          },
          minzoom: 8,
        });
        map.addLayer({
          id: "irrigated_line",
          type: "line",
          source: "irrigated",
          layout: {
            visibility: layersOverride.includes("certs") ? "visible" : "none",
          },
          paint: {
            "line-color": "#20d62c",
            "line-width": 2,
          },
          minzoom: 11.5,
        });
        map.addLayer({
          id: "irrigated_symbol",
          type: "symbol",
          source: "irrigated",
          layout: {
            "text-field": "Irrigated",
            visibility: layersOverride.includes("certs") ? "visible" : "none",
          },
          paint: {
            "text-color": "#20d62c",
          },
          maxzoom: 14.5,
        });
      }
      if (!map.getSource("retirements")) {
        map.addSource("retirements", {
          type: "geojson",
          data: retirementGeo || emptyCollection,
        });
        map.addLayer({
          id: "retirements_fill",
          type: "fill",
          source: "retirements",
          layout: {
            visibility: layersOverride.includes("certs") ? "visible" : "none",
          },
          paint: {
            "fill-color": "#e64343",
            "fill-opacity": 0.4,
          },
          minzoom: 8,
        });
        map.addLayer({
          id: "retirements_line",
          type: "line",
          source: "retirements",
          layout: {
            visibility: layersOverride.includes("certs") ? "visible" : "none",
          },
          paint: {
            "line-color": "#db2121",
            "line-width": 2,
          },
          minzoom: 11.5,
        });
        map.addLayer({
          id: "retirements_symbol",
          type: "symbol",
          source: "retirements",
          layout: {
            "text-field": "Retirement {displayId}",
            visibility: layersOverride.includes("certs") ? "visible" : "none",
          },
          paint: {
            "text-color": "#b01212",
          },
          maxzoom: 14.5,
        });
      }

      // add well & FM clusters
      if (!map.getSource("pointGeom")) {
        map.addSource("pointGeom", {
          type: "geojson",
          data: pointGeom || emptyCollection,
          cluster: true,
          clusterRadius: 40,
          clusterMinPoints: 2,
        });

        if (labelWellsWithRegNo) {
          map.addLayer({
            id: "pointGeom",
            type: "symbol",
            source: "pointGeom",
            layout: {
              "text-field": "{displayId}",
              "text-allow-overlap": true,
              "text-size": 10,
              "text-ignore-placement": true,
            },
            paint: {
              "text-color": "#dddddd",
            },
          });
        } else {
          map.addLayer({
            id: "clusters",
            type: "circle",
            source: "pointGeom",
            filter: ["has", "point_count"],
            paint: {
              "circle-color": "#24cbf1",
              "circle-radius": [
                "step",
                ["get", "point_count"],
                20,
                100,
                30,
                750,
                40,
              ],
            },
            minzoom: MARKER_MIN_ZOOM,
          });

          map.addLayer({
            id: "clusterCounters",
            type: "symbol",
            source: "pointGeom",
            filter: ["has", "point_count"],
            layout: {
              "text-field": "{point_count_abbreviated}",
            },
            paint: {
              "text-color": "#fff",
            },
            minzoom: MARKER_MIN_ZOOM,
          });

          Promise.all([
            loadImage(map, "well"),
            loadImage(map, "irrWell"),
            loadImage(map, "monWell"),
            loadImage(map, "miWell"),
            loadImage(map, "fmGreen"),
            loadImage(map, "fmRed"),
            loadImage(map, "chemigation"),
            loadImage(map, "soil"),
            loadImage(map, "soilHighlight"),
            loadImage(map, "meterHighlight"),
            loadImage(map, "wellHighlight"),
            loadImage(map, "chemHighlight"),
          ]).then(() => {
            map.addLayer({
              id: "pointGeom",
              type: "symbol",
              source: "pointGeom",
              filter: ["!", ["has", "point_count"]],
              layout: {
                "icon-image": "{iconType}",
                "icon-size": iconSize ? iconSize : 1,
              },
              ...(!alwaysShowIcons && { minzoom: MARKER_MIN_ZOOM }),
            });
          });
        }
      }
    });
  }, [
    mapRef,
    certGeom,
    irrigatedGeo,
    pointGeom,
    retirementGeo,
    layersOverride,
    alwaysShowIcons,
    wur,
    labelWellsWithRegNo,
    shouldAddNRDBoundaries,
    iconSize,
  ]);
}
