import React from "react";
import {
  ArrowPathIcon,
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { LineChart, Line } from "recharts";

const HighLight = ({ data, className }) => {
  const { chartProps, displayRefreshButton } = data;
  return (
    <div
      className={twMerge(
        "w-1/4 rounded-lg bg-white dark:bg-gray-900 flex flex-row-reverse items-center p-4 gap-x-3",
        className
      )}
    >
      {chartProps ? (
        <div>
          {chartProps.customChart ? (
            chartProps.customChart
          ) : (
            <LineChart width={100} height={100} data={chartProps.chartData}>
              <Line
                type="monotone"
                dot={false}
                dataKey="value"
                stroke={chartProps.lineColor}
                strokeWidth={2}
              />
            </LineChart>
          )}
        </div>
      ) : displayRefreshButton ? (
        <div className="bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center border border-blue-200 cursor-pointer">
          <ArrowPathIcon color="#376eed" className="w-5 h-5" />
        </div>
      ) : null}
      <div className="flex-1">
        <div className="text-sm text-gray-500 dark:text-white font-medium">
          {data.title}
        </div>
        <div className="text-xl my-3 font-bold dark:text-white">
          {data.value}
        </div>
        {data.percentage ? (
          <span className="text-sm font-medium flex items-center">
            <span
              className={`font-bold flex items-center ${
                data.percentage > 0 ? "text-green-600" : "text-red-600"
              }`}
            >
              <span>{data.percentage}%</span>
              {data.percentage > 0 ? (
                <ArrowSmallUpIcon
                  color="#16a34a"
                  className="w-4 h-4 inline-block"
                />
              ) : (
                <ArrowSmallDownIcon
                  color="#dc2626"
                  className="w-4 h-4 inline-block"
                />
              )}
            </span>
            <span className="text-xs text-gray-500 dark:text-white ml-1">
              than last year
            </span>
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default HighLight;
