import React, { createContext, useContext, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AppActionCreators from "../reducers/appSlice";
import useFetch from "../hooks/useFetch";

const AppContext = createContext();

const AppContextProvider = (props) => {
  const { children, appState, dispatch } = props;
  const { data: dbProd } = useFetch("/db-is-prod");

  const appApi = useMemo(
    () => bindActionCreators(AppActionCreators, dispatch),
    [dispatch]
  );

  useEffect(() => {
    if (
      dbProd?.data?.["prod-db"] === true ||
      dbProd?.data?.["prod-db"] === null
    ) {
      const link = document.querySelector("link[rel~='icon']");
      if (!link.href.includes("PC_FavIcon.png")) {
        link.href = "/PC_FavIcon.png";
        appApi.setProd(true);
      }
    } else {
      const link = document.querySelector("link[rel~='icon']");
      if (!link.href.includes("PC_FavIcon_Red.png")) {
        link.href = "/PC_FavIcon_Red.png";
        appApi.setProd(false);
      }
    }
  }, [appApi, dbProd]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedTheme = localStorage.getItem("theme");
      if (storedTheme !== appState.theme) {
        appApi.setTheme(storedTheme);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [appApi, appState.theme]);

  useEffect(() => {
    const root = window.document.documentElement;

    const applyTheme = (theme) => {
      root.classList.remove("light", "dark");
      if (theme === "system") {
        const systemPreference = window.matchMedia(
          "(prefers-color-scheme: dark)"
        );
        root.classList.add(systemPreference.matches ? "dark" : "light");
        return systemPreference.matches;
      } else {
        root.classList.add(theme);
        return theme === "dark";
      }
    };

    const handleSystemThemeChange = (e) => {
      if (appState.theme === "system") {
        applyTheme(e.matches ? "dark" : "light");
      }
    };

    if (appState.theme === "system") {
      const systemPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      );
      applyTheme(systemPreference.matches ? "dark" : "light");
      systemPreference.addEventListener("change", handleSystemThemeChange);
      return () => {
        systemPreference.removeEventListener("change", handleSystemThemeChange);
      };
    } else {
      applyTheme(appState.theme);
    }
  }, [appState.theme]);

  const contextValue = useMemo(() => [appState, appApi], [appApi, appState]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

const mapStateToProps = ({ app }) => ({ appState: app });

export default connect(mapStateToProps)(AppContextProvider);

export const useAppContext = () => useContext(AppContext);
