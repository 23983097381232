import React from "react";
import { Dialog } from "@headlessui/react";
import Loading from "./Loading";
import { useAppContext } from "../../context/app";

const GlobalLoading = () => {
  const [{ loading }] = useAppContext();

  return (
    <Dialog
      open={loading}
      as="div"
      className="relative z-40"
      onClose={() => {}}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed z-100 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full">
          <Loading />
        </div>
      </div>
    </Dialog>
  );
};

export default GlobalLoading;
