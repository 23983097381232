import React, { useMemo, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { Form, Formik } from "formik";
import Input from "../common/form/Input";
import { putRequest } from "../../utils/fetch";
import toast from "react-hot-toast";
import Button from "../common/Button";
import { Link } from "react-router-dom";

const PersonalSection = () => {
  const [loading, setLoading] = useState(false);

  const { data } = useFetch("/entity");

  const initialValues = useMemo(() => {
    if (!data) {
      return null;
    }
    return {
      Address: data.Address,
      Address2: data.Address2,
      City: data.City,
      State: data.State,
      ZipCode: data.ZipCode,
      Email: data.Email,
      MobilePhone: data.MobilePhone,
      HomePhone: data.HomePhone,
      BusinessPhone: data.BusinessPhone,
    };
  }, [data]);

  return (
    <>
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7">
            Personal Information
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            Use a permanent address where you can receive mail.
          </p>
        </div>

        <div className="md:col-span-2">
          {initialValues && (
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {
                try {
                  setLoading(true);
                  await putRequest({
                    url: "/entity",
                    data: {
                      ...values,
                      Email: undefined,
                    },
                  });
                  toast.success("Your setting has been saved!");
                } catch (e) {
                  toast.error(e.message);
                } finally {
                  setLoading(false);
                }
              }}
            >
              {(props) => (
                <Form className="grid grid-cols-2 gap-4">
                  <Input disabled name="Email" label="Email" />
                  <Input name="MobilePhone" label="Mobile Phone" />
                  <Input name="HomePhone" label="Home Phone" />
                  <Input name="BusinessPhone" label="Business Phone" />
                  <Input name="Address" label="Address" />
                  <Input name="Address2" label="Address 2" />
                  <Input name="City" label="City" />
                  <Input name="State" label="State" />
                  <Input name="ZipCode" label="ZipCode" />
                  <div className="col-span-2">
                    <Button type="submit" disabled={loading} loading={loading}>
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>

        <a
          className="col-start-3 text-end underline text-blue-600"
          href="/privacy.html"
        >
          Privacy Policy
        </a>
      </div>
    </>
  );
};

export default PersonalSection;
