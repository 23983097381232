const convertToFeatureCollection = (
  data,
  labelKey,
  passThroughKeys = [],
  type
) => ({
  type: "FeatureCollection",
  features: (data || [])
    .filter((doc) => doc?.geometry?.coordinates)
    .map((doc) => ({
      type: "Feature",
      geometry: doc.geometry,
      properties: {
        id: doc._id,
        displayId: doc[labelKey],
        type: doc["type"] || type,
        ...passThroughKeys.reduce(
          (acc, key) => ({ ...acc, [key]: doc[key] }),
          {}
        ),
      },
    })),
});

export default convertToFeatureCollection;
