import { useEffect } from "react";

export default function useMapDataUpdate({
  mapRef,
  certGeom,
  irrigatedGeo,
  pointGeom,
  retirementGeo,
  sectionsGeo,
  townshipsGeo
}) {
  useEffect(() => {
    const map = mapRef.current;
    if (map && map.getSource("certs")) {
      map.getSource("certs").setData(certGeom);
    }
  }, [mapRef, certGeom]);
  useEffect(() => {
    const map = mapRef.current;
    if (map && map.getSource("irrigated")) {
      map.getSource("irrigated").setData(irrigatedGeo);
    }
  }, [mapRef, irrigatedGeo]);
  useEffect(() => {
    const map = mapRef.current;
    if (map && map.getSource("pointGeom")) {
      map.getSource("pointGeom").setData(pointGeom);
    }
  }, [mapRef, pointGeom]);
  useEffect(() => {
    const map = mapRef.current;
    if (map && map.getSource("retirements")) {
      map.getSource("retirements").setData(retirementGeo);
    }
  }, [mapRef, retirementGeo]);
  useEffect(() => {
    const map = mapRef.current;
    if (map && map.getSource('Sections')) {
      map.getSource('Sections').setData(sectionsGeo);
    }
  }, [mapRef, sectionsGeo]);
  useEffect(() => {
    const map = mapRef.current;
    if (map && map.getSource('Townships')) {
      map.getSource('Townships').setData(townshipsGeo);
    }
  }, [mapRef, townshipsGeo]);
}
