import roundTo from "./roundTo";

// Get Data to display into the Popup
export const getDataWithType = (type, properties) => {
  switch (type) {
    case "Flowmeter":
      return {
        Meter_Location: properties["Meter_Location"] || "",
        Serial_num: properties["Serial_num"] || "",
      };
    case "Well":
      return {
        Well_Type: properties["Well_Type"] || "",
        Reg_No: properties["Reg_No"] || "",
      };
    default:
      return null;
  }
};

export const parseFloatPercentage = (val) =>
  val && !isNaN(val) ? roundTo(100 * Number(val), 10).toString() : "";

export const calculateIconSize = (zoom) => {
  // Calculate meters per pixel based on zoom level (assuming latitude of 0 for simplicity)
  const metersPerPixel = (156543.03392 * Math.cos(0)) / Math.pow(2, zoom);

  // Calculate number of pixels needed for a 750m diameter
  const numberOfPixels = 750 / metersPerPixel;

  // Determine icon size based on number of pixels (assuming 30x30 icon)
  const iconSize = numberOfPixels / 30;

  return iconSize;
};

export const getChartData = (data, dataKey) =>
  Object.entries(data[dataKey] || {})
    .map((item) => ({
      dateStr: item[0],
      value: item[1],
    }))
    .sort((a, b) => (a.dateStr > b.dateStr ? 1 : -1));

export const getLastestValueForChart = (data, dataKey) => {
  const years = Object.keys(data[dataKey] || {}).sort((a, b) =>
    a > b ? -1 : 1
  );
  return years.length ? data[dataKey][years[0]] : 0;
};

export const scrollToItem = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
