import React from "react";
import { Badge } from "flowbite-react";

const ActivityIcon = ({ active, onText = "Active", offText = "Inactive" }) => (
  <Badge
    size="sm"
    color={active ? "success" : "failure"}
    className="inline w-fit"
  >
    {active ? onText : offText}
  </Badge>
);

export default ActivityIcon;
