import React, { useMemo } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import useChartWidth from "../../hooks/useChartWidth";
import displayValue from "../../utils/displayValue";
import CustomizedDot from "../common/CustomizedDot";
import moment from "moment";

const SoilHealthTrendsChart = ({ data }) => {
  const width = useChartWidth("soil-health-chart", window.innerWidth);

  const chartData = useMemo(() => {
    if (data?.soil_samples) {
      const dataObject = data.soil_samples.reduce((result, item) => {
        const year = moment(item.sample_date).year();
        if (!result[year]) {
          result[year] = {
            Nitrate: 0,
            Organic_Matter: 0,
          };
        }
        result[year] = {
          Nitrate: result[year].Nitrate + item.n_lbs,
          Organic_Matter: result[year].Organic_Matter + item.organic_matter,
        };
        return result;
      }, {});
      return Object.entries(dataObject)
        .map(([key, value]) => ({
          dateStr: key,
          ...value,
        }))
        .sort((a, b) => (a.dateStr > b.dateStr ? 1 : -1));
    }
    return [];
  }, [data?.soil_samples]);

  return (
    <div id="soil-health-chart" className="w-full">
      <LineChart
        width={width}
        height={450}
        data={chartData}
        margin={{
          top: 20,
          right: 10,
          left: 10,
          bottom: 10,
        }}
      >
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="Organic_Matter"
          stroke="#9DC34F"
          strokeWidth={2}
          dot={<CustomizedDot />}
          isAnimationActive
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="Nitrate"
          stroke="#65A7DD"
          strokeWidth={2}
          dot={<CustomizedDot />}
          isAnimationActive
        />
        <XAxis dataKey="dateStr" />
        <YAxis yAxisId="left" tick={false} />
        <YAxis yAxisId="right" orientation="right" tick={false} />
        <Tooltip
          formatter={(value, name) => [
            `${name.replace("_", " ")}: ${value} ${
              name === "Nitrate" ? "(lbs)" : "(%)"
            }`,
          ]}
          labelFormatter={(el) => displayValue(el, { date: true })}
        />
      </LineChart>
    </div>
  );
};

export default SoilHealthTrendsChart;
